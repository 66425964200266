import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { gsap } from "gsap";
import developer from "../../assets/gif/net.gif"
import solution from "../../assets/gif/box.gif"
import house from "../../assets/gif/warn.gif"
import manager from "../../assets/gif/cam.gif"
export const Infracontent = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);


  return (
    <div id="features" className="text-center">
      <div style={{ marginTop: "5%" }} className="container" data-aos="fade-up">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Pulse AIOps

</h2>
          <p>
          Real-Time Network Performance Monitoring
        
          </p>
        </div>
        <div
      className="col-xs-6 col-md-3 developer-card"
      data-aos="fade-down"
      data-aos-duration="1000"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
        transform: isHovered ? "scale(1.1)" : "scale(1)",
        opacity: isHovered ? 1 : 0.9, // Adjust opacity values as needed
      }}
    >
      <div className="icon-container">

    
        
        { (isHovered  ?  <img
            src={developer}
             style={{ borderRadius: "20px", width: 120, objectFit: "cover" }}
            alt="Seasoned Developers"
            className="icon-container"
          /> : <i className="fa fa-users"></i>  
         
        )}
      </div>
      <h3>Comprehensive Network Visibility</h3>
      <p style={{textAlign: "left"}}>
      Gain complete control over your network health through comprehensive monitoring of servers, routers, switches, and other network elements

      </p>
    </div>
        <div
          className="col-xs-6 col-md-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered2(true)}
      onMouseLeave={() => setIsHovered2(false)}
      style={{
        transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
        transform: isHovered2 ? "scale(1.1)" : "scale(1)",
        opacity: isHovered2 ? 1 : 0.9, // Adjust opacity values as needed
      }}
        >

         

 <div className="icon-container">

    
        
        { (isHovered2  ?  <img
            src={solution}
            style={{ borderRadius: "20px", width: 120, objectFit: "cover" }}
   
            alt="Seasoned Developers"
            className="icon-container"
          /> :  <i className="fa fa-sitemap"></i>
         
        )}
      </div>

          <h3>Performance Optimization</h3>
          <p style={{textAlign: "left"}}>
          Identify and troubleshoot performance bottlenecks proactively to ensure optimal network performance and user experience

          </p>
        </div>
        <div
          className="col-xs-6 col-md-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered3(true)}
          onMouseLeave={() => setIsHovered3(false)}
          style={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered3 ? "scale(1.1)" : "scale(1)",
            opacity: isHovered3 ? 1 : 0.9, // Adjust opacity values as needed
          }}
        >

<div className="icon-container">

    
        
{ (isHovered3  ?  <img
    src={house}
    style={{ borderRadius: "20px", width: 120, objectFit: "cover" }}
   
    alt="Seasoned Developers"
    className="icon-container"
  /> :   <i className="fa fa-thumbs-up"></i>
 
)}
</div>

         





          <h3>Alert Management</h3>
          <p style={{textAlign: "left"}}>
          Receive real-time alerts for potential issues and network anomalies, allowing for prompt resolution and reduced downtime

          </p>
        </div>
        <div
          className="col-xs-6 col-md-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered4(true)}
          onMouseLeave={() => setIsHovered4(false)}
          style={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered4 ? "scale(1.1)" : "scale(1)",
            opacity: isHovered4 ? 1 : 0.9, // Adjust opacity values as needed
          }}
        >


<div className="icon-container">

    
        
{ (isHovered4  ?  <img
    src={manager}
    style={{ borderRadius: "20px", width: 120, objectFit: "cover" }}
   
    alt="Seasoned Developers"
    className="icon-container"
  /> : <i className="fa fa-shield"></i>
 
)}
</div>

          



          <h3>Detailed Reporting and Analytics</h3>
          <p style={{textAlign: "left"}}>
          Generate insightful reports and analyze network performance trends to identify areas for improvement and enhance overall network efficiency

          </p>
        </div>


      </div>
    </div>
  );
};
