import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bgimage from "../../assets/bgforcontact.jpg";
import { Alert } from '@mui/material';
import { Snackbar,Card } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha"; 
import CircularProgress from '@mui/material/CircularProgress';

export const Careers = () => {
 
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    

  }, []);


  const getIPAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      if (response.ok) {
        const data = await response.json();
        return data.ip;
      } else {
        console.error('Failed to fetch IP address');
        return null;
      }
    } catch (error) {
      console.error('An error occurred while fetching IP address:', error);
      return null;
    }
  };
  const [reCAPTCHA, setreCAPTCHA] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const showSnackbar = (message, severity) => {
    const capitalizedMessage = message.charAt(0).toUpperCase() + message.slice(1);
  
  setSnackbarMessage(capitalizedMessage);
  setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const [submited, setSubmited] = useState(false);
  const gradients = ['linear-gradient(to down, #B0E0E6, #2C5CDF)'];
  const [randomGradient, setRandomGradient] = useState('');
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Message: '',
  });
  const [formErrors, setFormErrors] = useState({
    Name: '',
    Email: '',
    Message: '',
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * gradients.length);
      setRandomGradient(gradients[randomIndex]);
    }, 500);

    return () => clearInterval(intervalId);
  }, [gradients]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
  
    if (name === 'Name') {
     
      newValue = newValue.replace(/[^A-Za-z\s]/g, '');
    }
  
    setFormData({ ...formData, [name]: newValue });
  };
  

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!formData.Name.trim()) {
      errors.Name = 'Full name is required';
      valid = false;
    }

    if (!reCAPTCHA) {
      showSnackbar('reCAPTCHA Verfication Required', 'warning');
      valid = false;
    }

    if (!formData.Email.trim()) {
      errors.Email = 'Email address is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.Email)) {
      errors.Email = 'Email address is invalid';
      valid = false;
    }

    if (!formData.Message.trim()) {
      errors.Message = 'Message is required';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  

  const handleSubmit = async(e) => {
    e.preventDefault();
   
    if (validateForm()) {
      setSubmited(true);
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
    const ip = await getIPAddress();
    const timestamp = new Date().toLocaleString(undefined, { hour12: true }); 
    formDatab.append('Ip', ip);
    formDatab.append('Date', timestamp);
      fetch(
        "https://script.google.com/macros/s/AKfycbykkmR_0JJ1c9-B5IuE8zlRPYTmm3Db9uqCLP2MFlurVuwqiHKfpmK7eQpEwhLsR85Ehg/exec",
        
        {
          method: "POST",
          body: formDatab
        }
      )
      .then((res) => {
        if (res.ok) {
          setSubmited(false)
          
          showSnackbar('Your Response Created Successfully', 'success');
setFormData({
  Name: '',
  Email: '',
  Message: '',
})


          
        } else {
          showSnackbar('An error occurred', 'error');
          setSubmited(false)
          
        }
      })
      
      
      .catch((error) => {
        
        setSubmited(false)
          
        console.log(error);
        showSnackbar('An error occurred', 'error');
      });
  };
  }


  
  return (
    <>
      <Snackbar open={snackbarOpen}    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
      autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert  variant="filled"  onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '400%' }}>
            {snackbarMessage}
           
          </Alert>
        </Snackbar>

    <div id="values" className="text-center" style={{ backgroundImage: `url(${bgimage})`, backgroundSize: "cover" }}>
   


   
  
       
         <div className="container" data-aos="fade-up">
       
        <Card style={{ borderRadius: 30 }}>
          <div className="col-md-10 col-md-offset-1 section-title">
            <h1 style={{ fontSize: 30 }}>Got a Spark of Inspiration? <br /> Let's Ignite a Project Together!</h1>
          </div>

          <form className="form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-3 col-xs-3 col-sm-3">
                <h6>Full name <span style={{color :'red'}}>*</span></h6>
              </div>
              <div className="col-md-8 col-lg-6 col-xs-8 col-sm-6">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="Name"
                  value={formData.Name}
                  onChange={handleInputChange}
                />
                {formErrors.Name && <span className="text-danger">{formErrors.Name}</span>}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3 col-xs-3 col-sm-3">
                <h6>Email address <span style={{color :'red'}}>*</span></h6>
              </div>
              <div className="col-md-8 col-lg-6 col-xs-8 col-sm-6">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  name="Email"
                  value={formData.Email}
                  onChange={handleInputChange}
                  placeholder="example@example.com"
                />
                {formErrors.Email && <span className="text-danger">{formErrors.Email}</span>}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3 col-xs-3 col-sm-3">
                <h6>Message <span style={{color :'red'}}>*</span></h6>
              </div>
              <div className="col-md-8 col-lg-6 col-xs-8 col-sm-6">
                <textarea
                  name="Message"
                  className="form-control"
                  rows="3"
                  value={formData.Message}
                  onChange={handleInputChange}
                  placeholder="Message sent to the RedRuby"
                ></textarea>
                {formErrors.Message && <span className="text-danger">{formErrors.Message}</span>}
              </div>
            </div>

            
            <div  className="row">
           

              <div style={{ padding:"20" , display:"flex",justifyContent:"center",alignItems:"center"}} className="col-md-12">
                <ReCAPTCHA
                  sitekey="6LdJYNQpAAAAAEV255-zmiHyHNEv1IOFTh39uFAc" 
                  onChange={() => setreCAPTCHA(true)} 
                />
              </div>
            </div>
            <br />
            <br />
            <div style={{ marginBottom: 20 }} className="px-5 py-4 pb-5">
              <button
                type="submit"
           disabled={submited}
                style={{ backgroundImage: randomGradient, fontWeight: "bold" }}
                data-mdb-button-init
                data-mdb-ripple-init
                className="btn btn-custom btn-lg page-scroll"
                data-aos="fade-up"
              >
               {submited ? <CircularProgress size={20} color="warning" /> :"Send"  }
              </button>
            </div>
          </form>
         
        </Card>
      
      </div>
    </div>
     
        </>
  );
};
