

// import JsonData from '../../../data/data.json'
import { Navigation } from '../navigation'
import Footer from '../footer'
import { Careers } from './careers'
const Careersfirstpage = () => {
 

  return (
    <div >
   <Navigation></Navigation>
<Careers></Careers>

<Footer/>

    </div>
  )
}

export default Careersfirstpage
