import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import developer from "../assets/gif/customer.gif"
import solution from "../assets/gif/brain.gif"
import house from "../assets/gif/score.gif"
import manager from "../assets/gif/team.gif"
import bgimage from "../assets/home/bgforsec.jpg"
import { Card } from "@mui/material";
export const OurValues = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div id="values" className="text-center"  style={{ backgroundImage: `url(${bgimage})`, backgroundSize: "cover" }}>
      <div  className="container" data-aos="fade-up">

      <Card style={{borderRadius:30}}  >
        
      <div className="col-md-10 col-md-offset-1 section-title">
<h2>Values</h2>

</div>

<div
className="col-xs-6 col-md-3"
data-aos="fade-down"
data-aos-duration="1000"
onMouseEnter={() => setIsHovered(true)}
onMouseLeave={() => setIsHovered(false)}
style={{
  transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
  transform: isHovered ? "scale(1.1)" : "scale(1)",
  opacity: isHovered ? 1 : 0.9, 
}}
>

{ (isHovered  ?  <img
  src={developer}
  width={110}
  style={{borderRadius:"20px" , backgroundColor:""  ,width: 120, objectFit: "cover" }}
  alt="Seasoned Developers"
  className="icon-container"
/> :   <i className="fa fa-users"></i>  

)}







<h3>Customer Focus</h3>
<p style={{textAlign: "left"}}>
We focus on strong client relationships and surpassing expectations.
</p>
</div>
<div
className="col-xs-6 col-md-3"
data-aos="fade-down"
data-aos-duration="1000"
onMouseEnter={() => setIsHovered2(true)}
onMouseLeave={() => setIsHovered2(false)}
style={{
  transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
  transform: isHovered2 ? "scale(1.1)" : "scale(1)",
  opacity: isHovered2 ? 1 : 0.9, // Adjust opacity values as needed
}}
>

<div className="icon-container">



{ (isHovered2  ?  <img
src={solution}
width={110}
style={{borderRadius:"20px" , backgroundColor:""  ,width: 120, objectFit: "cover" }}
alt="Seasoned Developers"
className="icon-container"
/> :   <i className="fa fa-cubes"></i>

)}
</div>





<h3>Innovation</h3>
<p style={{textAlign: "left"}}>
We encourage creativity and exploration, always looking for new ways to use technology.
</p>
</div>
<div
className="col-xs-6 col-md-3 "
data-aos="fade-down"
data-aos-duration="1000"
onMouseEnter={() => setIsHovered3(true)}
onMouseLeave={() => setIsHovered3(false)}
style={{
  transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
  transform: isHovered3 ? "scale(1.1)" : "scale(1)",
  opacity: isHovered3 ? 1 : 0.9, // Adjust opacity values as needed
}}
>



<div className="icon-container">



{ (isHovered3  ?  <img
src={house}
width={110}
style={{borderRadius:"20px" , backgroundColor:""  ,width: 120, objectFit: "cover" }}
alt="Seasoned Developers"
className="icon-container"
/> :   <i className="fa fa-university"></i>

)}
</div>






<h3>Integrity</h3>
<p style={{textAlign: "left"}}>
  We conduct ourselves with honesty and transparency in all our
  interactions.
</p>
</div>
<div
className="col-xs-6 col-md-3"
data-aos="fade-down"
data-aos-duration="1000"
onMouseEnter={() => setIsHovered4(true)}
onMouseLeave={() => setIsHovered4(false)}
style={{
  transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
  transform: isHovered4 ? "scale(1.1)" : "scale(1)",
  opacity: isHovered4 ? 1 : 0.9, // Adjust opacity values as needed
}}
>




<div className="icon-container">



{ (isHovered4  ?  <img
src={manager}
width={110}
style={{borderRadius:"20px" , backgroundColor:""  ,width: 120, objectFit: "cover" }}
alt="Seasoned Developers"
className="icon-container"
/> :  <i className="fa fa-shield"></i>


)}
</div>


<h3>Teamwork</h3>
<p style={{textAlign: "left"}}>
We value collaboration and foster an inclusive work environment.
</p>
</div>
        
        </Card>

        
      </div>
    </div>
  );
};


