






import about from "../../../../assets/robot.jpg"
import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
export const Docabout = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Adjust the duration as needed
     
    });
  }, []);
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'  >
            {' '}
            <img style={{height:500  }}  data-aos='fade-up' src={about} className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'  data-aos='fade-up'>
            <div className='about-text'>
              <h2>What is GyanBuddy KMS ? </h2>
              <p> GyanBuddy KMS is a revolutionary knowledge management system that leverages the power of AI to transform how your organization accesses and utilizes information. It acts as a central repository for all your documents, making them readily searchable and accessible to everyone.  Imagine an organization-wide Google search that understands your intent and delivers relevant information instantly, combined with the conversational power of an internal ChatGPT for real-time knowledge exchange</p>
           

 
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
