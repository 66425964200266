






import about from "../../assets/icons/globalization.png"
import React, { useEffect } from 'react';
import { Card } from "@mui/material";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Digipara = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
<Card>
<div className='container'>
        <div className='row'>

          <div className='col-xs-12 col-md-6'  data-aos='fade-left'>
            <div className='about-text'>
              <h2>Digital Migration Services</h2>

             


              <p>Looking to seamlessly transition your operations to the digital realm?
</p>
           <p> We provide comprehensive digital migration services, ensuring a smooth and secure transfer of your data and processes to the latest technologies.

       </p>    
            </div>
          </div>
          <div className='col-xs-12 col-md-6'  >
            <img style={{height:300  }}  data-aos='fade-right' src={about} className='img-responsive' alt='' />{' '}
          </div>
        </div>
      </div>
    </Card>
    </div>
  )
}

export default  Digipara