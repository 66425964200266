






import React, { useEffect } from 'react';
import node from "../../assets/tech/openapi.png"

import python from "../../assets/tech/restapi.png"
import php from "../../assets/tech/swagger.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Api = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
      <div className='container'>
        <div className='row'>
        <h2>API management
</h2>
          <div className='col-xs-12 col-md-12'  >
            {' '}
         <div style={{ marginBottom:30, display: 'flex', flexWrap: 'wrap'  , justifyContent:'space-evenly' }}   >  
         
         
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
         <img  style={{height:150 , width:150  }}  data-aos='fade-up' src={python} className='grayscale-img' alt='' />{' '}
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>RESTAPI </p>
</div> 

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
<img style={{height:150 , width:150 }}  data-aos='fade-up' src={php} className='img-responsive grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>SWAGGER </p>
       </div> 
         
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={node} className='img-responsive grayscale-img' alt='' />{' '} 

       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>OPENAPI </p>
       </div> 
          
            
            
             </div>
       
       
          </div>
          <div className='col-xs-12 col-md-'  data-aos='fade-up'>
            <div className='about-text'>

           
<p>Through a number of technologies, we can safely enable API access, controlling and limiting it to keep your business safe and secure.





</p>
         
             
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default  Api