import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import "./timeline.css";

const Timeline = () => {
  useEffect(() => {
    const tl = gsap.timeline({ paused: true });

    tl.from('.timeline', { opacity: 0, y: 100, stagger: 0.2 });

    const handleScroll = () => {
      if (window.scrollY > 100) {
        tl.play();
      } else {
        tl.reverse();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


    
  const timelineData = [



    {
      color: '#46B2BC',

      year:"fa fa-trophy",
      title: 'Clearly Define Your Project Requirements',
      description:
        " We'll help you articulate your vision, identify key functionalities, and establish measurable success criteria.",
    },
    {

      color: '#EA3C14',
      year:"fa fa-pencil-square-o",
      title: 'Evaluate Existing Systems',
      description:
        "We'll assess your current technology landscape to identify areas for improvement and opportunities for optimization",
    },
    {
   
      color: '#6CAF28',
      year:"fa fa-user fa-5x",
      title: 'Recommend the Right Solutions',
      description:
        "Our technology-agnostic approach ensures we recommend the most fitting solutions to address your specific needs, not just promote specific products",
    },
    {
     
      color: '#F99324',
      year:"fa fa-sitemap fa-5x",
      title: 'Develop a Strategic Roadmap',
      description:
        "We'll create a tailored roadmap that outlines project phases, timelines, and resource allocation",
    },
    {
    
      color: '#2250A3',
      year:"fa fa-thumbs-up fa-5x",
      title: 'Mitigate Risks and Challenges',
      description:
        "We'll proactively identify potential risks and develop strategies to mitigate them, ensuring project success",
    },
    {
   
      color: '#46B2BC',
      year:"fa fa-paper-plane-o fa-5x",
      title: 'Provide Ongoing Support',
      description:
      "Our support extends beyond the initial consultation phase. We'll be there to guide you throughout your project journey",
    },

  ];

  return (
    <div id='testimonials' style={{backgroundColor:'white',}} className="container" >
      <h2 style={{marginBottom:30 , textAlign:'center'}} > Approach </h2>
      <div className="row">
        <div className="col">
          <div className="main-timeline">
            {timelineData.map((item, index) => (
              <div className="timeline" key={index}>
                <a className="timeline-content">
                  {/* <span className="timeline-year">{item.year}</span> */}
                  <div className="timeline-year">
                  <i className={item.year} style={{ color: item.color, fontSize: '1.7em' }} fa-4x aria-hidden="true"></i>
                  </div>
                  <div className="content">
                    <h3 className="title">{item.title}</h3>
                    <p className="description">{item.description}</p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
