import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from "@mui/material";
import image from "../assets/svg/box.svg";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import "./dialog.css"
import { Email, Schedule } from '@mui/icons-material'; 


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EnquiryDialog = ({ isOpen, onClose, onSubmit }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('phoneNumber', phoneNumber);
    formData.append('email', email);

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbwf_kZ1tZ2RzhBZ8hfumceblx_8lgCyNgKX1ZmTw5q-C9wN7pGsWRGkgjs0Sopjg_rG/exec', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Form submitted successfully:', data);
        onSubmit(data); 
        onClose(); 
      } else {
        console.error('Failed to submit form:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const gradients = ['linear-gradient(to down, #B0E0E6, #2C5CDF)',];

  const [randomGradient, setRandomGradient] = useState('');
  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * gradients.length);
      setRandomGradient(gradients[randomIndex]);
    }, 500);

    return () => clearInterval(intervalId);
  }, [gradients]);

  return (
    <Dialog open={isOpen} TransitionComponent={Transition} keepMounted onClose={onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center", alignContent: "center", fontSize: 25 }} className="philosopher-regular-italic">Enquiry us </DialogTitle>
     
      <DialogContent>
          <img src={image} alt="Enquiry Image" style={{ width: '100%', marginBottom: 10 }} />
          <TextField
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="dense"
            sx={{ fontFamily: 'Montserrat', fontWeight: 400,  marginBottom: 2 }}
          />
          <TextField
            label="Phone Number"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            margin="dense"
            sx={{ fontFamily: 'Montserrat', fontWeight: 400, marginBottom: 2 }}
          />
          <TextField
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="dense"
            sx={{ fontFamily: 'Montserrat', fontWeight: 400, marginBottom: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" sx={{ color:"#00215E", fontFamily: 'Montserrat' , fontWeight: 400, fontSize:12 }}>
            Cancel
          </Button>
          <Button style={{ backgroundColor: "#00215E", backgroundImage: randomGradient, fontWeight: "bold" , color:"white"}} className="" onClick={handleSubmit} color="primary" sx={{ fontFamily: 'Montserrat' , fontWeight: 100, fontSize:15}}>
            Submit
          </Button>
        
        </DialogActions>
    </Dialog>
  );
};

export default EnquiryDialog;
