






import React, { useEffect } from 'react';
import node from "../../assets/tech/nodejs.png"

import python from "../../assets/tech/python.jpeg"
import php from "../../assets/tech/php.jpeg"
import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Carousel = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
      <div className='container'>
        <div className='row'>
        <h2>Backend Engineering</h2>
          <div className='col-xs-12 col-md-12'  >
            {' '}

          
         <div style={{ marginBottom:30, display: 'flex', flexWrap: 'wrap'  , justifyContent:'space-evenly' }}   >  
         
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
  <img style={{ height: 150, width: 150 }} data-aos='fade-up' src={python} className='grayscale-img' alt='Python Logo' />
  <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>PYTHON</p>
</div> 

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
  <img style={{ height: 150, width: 150 }} data-aos='fade-up' src={php} className='img-responsive grayscale-img' alt='PHP Logo' />
  <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0',
    borderRadius: '12px',
    fontWeight: 'bold',
    marginTop: '10px'
  }}>PHP</p>
</div> 

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
  <img style={{ height: 150, width: 150 }} data-aos='fade-up' src={node} className='img-responsive grayscale-img' alt='Node.js Logo' />
  <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0',
    borderRadius: '12px',
    fontWeight: 'bold',
    marginTop: '10px'
  }}>NODE.JS</p>
</div>
            
             </div>
       
       
          </div>
          <div className='col-xs-12 col-md-'  data-aos='fade-up'>
            <div className='about-text'>
       
           
<p>We use a plethora of programming languages, like python, php , NodeJS  for building Microservices based scalable back-end platforms
</p>
         
             
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default  Carousel