import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import image1 from  "../../assets/ittl.jpg";
import image2 from  "../../assets/sla.jpg";
import image3 from  "../../assets/workflow.jpg";

export const Infrapic = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const data = [
    { thumb: image1, title: "Automated ITIL Processes", subtitle: "Automate key ITIL processes, such as incident management, problem management, and change management, for improved operational efficiency. " },
    { thumb: image2, title: "Effective SLA Management", subtitle: "Define, monitor, and enforce service level agreements (SLAs) to ensure consistent service delivery and meet stakeholder expectations" },
    { thumb: image3, title: "Streamlined Workflows", subtitle: "Break down silos between IT asset management and network monitoring, facilitating a collaborative approach to resolving issues"
     },
   
   

  ];

  const images = data.map((obj) => obj.thumb.replace("-small", "-large"));

 
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
        <h2>Pulse ITIL</h2>
          <h3>Automatic ITIL and SLA Management</h3>

        </div>
        <div className="row">
          <div className="portfolio-items">
            {data.map(({ title, thumb, subtitle }, index) => (
              <div key={index}  className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item " style={{ height: "300px", width: "100%", overflow: "hidden" }}>
                  <div className="hover-bg">
                    <div className="hover-text">
                   
                      <p style={{color:"white"}} >{subtitle}</p>
                    </div>

                 

                    <img
                      src={thumb}
                      style={{ height: "100%", width: "100%", objectFit: "cover" }}
                      className="img-responsive"
                      alt="Project Title"
                    />
                               
                  </div>
                </div>
                <h4>{title}</h4>      
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
};
