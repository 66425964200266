import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import image1 from  "../../../assets/shaking.jpg";
import image2 from  "../../../assets/gear.jpg";
import image3 from  "../../../assets/workflow.jpg";
import image4 from  "../../../assets/businessmanworking.jpg";
import image5 from  "../../../assets/focus.jpg";
export const Comppic = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const data = [
    { thumb: image1, title: "Service Level Agreements (SLAs)", subtitle: "Experience clear and measurable performance guarantees for our services.    " },
    { thumb: image2, title: "Proactive Management", subtitle: " Identify and address potential issues before they become problems.    " },
    { thumb: image3, title: "Expert Technicians", subtitle: "Benefit from our team's extensive experience and industry certifications.    "
     },
     { thumb: image4, title: "Transparent Communication", subtitle: "Stay informed about the status of your IT systems at all times."
    },
    { thumb: image5, title: "Focus on Your Business", subtitle: "Free yourself from IT worries and concentrate on what you do best. "
}, 
   

  ];

  const images = data.map((obj) => obj.thumb.replace("-small", "-large"));

 
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Advantage's of RedRuby (IT services) </h2>

        </div>
        <div className="row">
          <div className="portfolio-items">
            {data.map(({ title, thumb, subtitle }, index) => (
              <div key={index}  className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item " style={{ height: "300px", width: "100%", overflow: "hidden" }}>
                  <div className="hover-bg">
                    <div className="hover-text">
                   
                      <p style={{color:"white"}} >{subtitle}</p>
                    </div>

                 

                    <img
                      src={thumb}
                      style={{ height: "100%", width: "100%", objectFit: "cover" }}
                      className="img-responsive"
                      alt="Project Title"
                    />
                               
                  </div>
                </div>
                <h4>{title}</h4>      
              </div>
            ))}
          </div>

         
        </div>
      </div>
    </div>
  );
};
