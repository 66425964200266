import { useState, useEffect } from 'react'

import JsonData from '../../../data/data.json'
import { Navigation } from '../../navigation'
import Footer from '../../footer'
import { CompleteHeader } from './completeheader'
import { CompleteContent } from './completecontent'

import {OurServices } from './ourservices'
import { Comppic } from './completepics'
import {Compabout } from './completeaboutpage'
import {Contactcomp } from './contact'
const Completefirstpage = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
    window.scrollTo(0, 0);
  }, [])

  return (
    <div >
   <Navigation></Navigation>
<CompleteHeader></CompleteHeader>
<Compabout/>


<OurServices></OurServices> 
<CompleteContent></CompleteContent>
<Comppic></Comppic>
<Contactcomp/>
<Footer/>

    </div>
  )
}

export default Completefirstpage
