import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";

export const Odoogallery = (props) => {

  const data = [
    { thumb: "img/portfolio/flag.jpg", title: "Government", subtitle: "Manage complex workflows, improve citizen service delivery, and ensure compliance with regulations" },
    { thumb: "img/portfolio/4534.jpg", title: "Education", subtitle: "Streamline student admissions, manage class schedules, and enhance communication between educators and parents" },
    { thumb: "img/portfolio/buss.jpg", title: "Business", subtitle: "Manage financials, projects, and customer relationships from a single platform." },
    { thumb: "img/portfolio/hr.jpg", title: "HR Management", subtitle: "Automate HR processes, simplify payroll, and improve talent management strategies" },
    { thumb: "img/portfolio/retails.jpg", title: "Retail" , subtitle:" Manage inventory, optimize sales processes, and gain valuable customer insights."},
    // { thumb: "img/portfolio/07-small.jpg", title: "Lorem Ipsum7" },

  ];

  const images = data.map((obj) => obj.thumb.replace("-small", "-large"));


  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Unity Solutions for Specific Sectors</h2>
          {/* <p>Odoo Unity Solutions for Specific Sectors</p> */}
        </div>
        <div className="row">
          <div className="portfolio-items">
            {data.map(({ title, thumb, subtitle }, index) => (
              <div key={index} className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item" style={{ height: "300px", width: "100%", overflow: "hidden" }}>
                  <div className="hover-bg">
                    <div className="hover-text">
                   
                      <p style={{color:"white"}} >{subtitle}</p>
                    </div>

                 

                    <img
                      src={thumb}
                      style={{ height: "100%", width: "100%", objectFit: "cover" }}
                      className="img-responsive"
                      alt="Project Title"
                    />
                               
                  </div>
                </div>
                <h4>{title}</h4>      
              </div>
            ))}
          </div>

     
        </div>
      </div>
    </div>
  );
};
