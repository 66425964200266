






import about from "../../../../assets/schl.jpg"
import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
export const Attenabout = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
     
    });
  }, []);
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'  >
            {' '}
            <img style={{height:600  }}  data-aos='fade-up' src={about} className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'  data-aos='fade-up'>
            <div className='about-text'>
              <h2>What is Face-Tag?</h2>
              <p> Face-Tag is a digital attendance management system designed to streamline and automate the process of tracking employee attendance within organizations. It offers features such as attendance tracking, integration with other systems, reporting capabilities, notifications for late arrivals or absences, and more. Face-tag aims to save time, improve accuracy, ensure compliance, and reduce costs associated with attendance management.</p>
           
              <h3>Features of Face-Tag</h3>
              <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                   
                     
                          <li >Camera-Agnostic</li>
                          <li >Monitor from Anywhere</li>
                          <li >Next-Gen Vision AI</li>
                         
                        
                   
                  </ul>
                </div>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                  <li >AI-Powered Architecture</li>
                    <li>Enterprise Scalability</li> 
                    <li>Cybersecurity</li>   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
