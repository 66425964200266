
import React, { useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import gsap from "gsap";
export const WhyChoose = () => {

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  useEffect(() => {
    const hoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1.3,
        duration: 0.2,
        onComplete: () => {
          gsap.set(ref, { scale: 1 });
        },
      });
    };

    const unhoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1,
        duration: 0.1,
      });
    };

    const addHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.addEventListener("mouseenter", () => hoverAnimation(element));
        element.addEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    const removeHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.removeEventListener("mouseenter", () => hoverAnimation(element));
        element.removeEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    addHoverListeners(ref1);
    addHoverListeners(ref2);
    addHoverListeners(ref3);
    addHoverListeners(ref4);
    addHoverListeners(ref5);
    return () => {
      removeHoverListeners(ref1);
      removeHoverListeners(ref2);
      removeHoverListeners(ref3);
      removeHoverListeners(ref4);
      removeHoverListeners(ref5);
    };
  }, []);
  return (
    <div id='services' className='text-center' style={{overflow:"hidden"}}>
      <div className='container'>
        <div className='section-title' data-aos='fade-up'>
          <h2>Why Choose Cyber Kavalan?</h2>
        </div>
        <div className='row' data-aos='fade-up'>
          <div  ref={ref1}  className='col-md-4'>
         
              <i className="fa fa-file-code-o"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Open-Source Advantage</h3>
                <p>Leverage the power of cost-effective yet robust open-source solutions like Wazuh for endpoint security.</p>
              </div>
       
          </div>

          <div  ref={ref2}  className='col-md-4'>
        
              <i className="fa fa-check"></i>
              
              <div className='service-desc' data-aos='fade-up'>
                <h3>NIST-Aligned Approach</h3>
                <p>Our methodologies are built upon the proven security frameworks outlined by the National Institute of Standards and Technology.</p>
              </div>
    
          </div>

          <div  ref={ref3}  className='col-md-4'>
        
              <i className="fa fa-user-secret"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Proactive Threat Detection</h3>
                <p>Go beyond basic defense with penetration testing and vulnerability scanning to identify and address potential security gaps proactively.</p>
              </div>
       
          </div>
          <div  ref={ref4}  className='col-md-12'>
        
              <i className="fa fa-star-o"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Empowered Workforce</h3>
                <p>A well-trained team is your first line of cyber defense. We equip your employees with the knowledge to combat cyber threats.</p>
              </div>
         
          </div>
         
        </div>
      </div>
    </div>
  );
}

