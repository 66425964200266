import { Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

export const Attenben = () => {

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);


  useEffect(() => {
    const hoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1.3,
        duration: 0.2,
        onComplete: () => {
          gsap.set(ref, { scale: 1 });
        },
      });
    };

    const unhoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1,
        duration: 0.1,
      });
    };

    const addHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.addEventListener("mouseenter", () => hoverAnimation(element));
        element.addEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    const removeHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.removeEventListener("mouseenter", () => hoverAnimation(element));
        element.removeEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    addHoverListeners(ref1);
    addHoverListeners(ref2);
    addHoverListeners(ref3);
    addHoverListeners(ref4);
    addHoverListeners(ref5);
    addHoverListeners(ref6);
    return () => {
      removeHoverListeners(ref1);
      removeHoverListeners(ref2);
      removeHoverListeners(ref3);
      removeHoverListeners(ref4);
      removeHoverListeners(ref5);
      removeHoverListeners(ref6);
    };
  }, []);

  return (
    <div id='services' className='text-center'  style={{overflow:"hidden"}}>
      <div className='container'>
        <div className='section-title' data-aos='fade-up'>
          <h2>How Face-Tag Benefits Your Organization</h2>
        </div>
        <div className='row' data-aos='fade-up'

        >
          <div ref={ref1} className='col-md-4'>
         


          <i class="fa fa-clock-o" aria-hidden="true"></i>



              <div className='service-desc' data-aos='fade-up'>
                <h3>Improved Efficiency</h3>
                <p> Automate attendance tracking and eliminate manual data entry</p>
              </div>
       
          </div>

          <div ref={ref2} className='col-md-4'>
           
          <i class="fa fa-crosshairs" aria-hidden="true"></i>

              
              <div className='service-desc' data-aos='fade-up'>
                <h3>Enhanced Accuracy</h3>
                <p> Reduce errors and ensure reliable attendance records
</p>
              </div>
          
          </div>

          <div ref={ref3} className='col-md-4'>
           
              <i className="fa fa-sitemap"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Increased Security</h3>
                <p>Prevent unauthorized access and ensure data integrity
</p>
              </div>
        
          </div>
          <div ref={ref4} className='col-md-4'>
           
          <i class="fa fa-inr" aria-hidden="true"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Cost Savings</h3>
                <p>Eliminate the need for traditional attendance systems and associated costs.
</p>
              </div>
      
          </div>
          <div ref={ref5} className='col-md-4'>
           
           <i class="fa fa-check-circle" aria-hidden="true"></i>
               <div className='service-desc' data-aos='fade-up'>
                 <h3>Real-Time Visibility</h3>
                 <p>Gain instant insights into attendance data for better decision-making.

 </p>
               </div>
       
           </div>
           <div ref={ref6} className='col-md-4'>
           
           <i class="fa fa-wrench" aria-hidden="true"></i>
               <div className='service-desc' data-aos='fade-up'>
                 <h3>Simplified Management</h3>
                 <p>Manage attendance effortlessly from a centralized platform.

 </p>
               </div>
       
           </div>

        </div>
      </div>
    </div>
  );
}

