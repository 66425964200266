import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import developer from "../assets/gif/coding.gif"
import solution from "../assets/gif/solution.gif"
import house from "../assets/gif/house.gif"
import manager from "../assets/gif/engineer.gif"
import bgimage from "../assets/home/bgformam.jpg"
import { Card } from "@mui/material";

export const Features = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);


  return (
    <div id="features" className="text-center" style={{ backgroundImage: `url(${bgimage})`, backgroundSize: "cover" }}>

      <div className="container" data-aos="fade-up">
        <Card style={{ borderRadius: 30 }}  >
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2  >Talent</h2>
            <p >
              RedRuby Technologies is driven by a team of enthusiastic and
              talented individuals who share a passion for technology and a
              commitment to client satisfaction.
            </p>
          </div>

          <div
            className="col-xs-12 col-sm-12 col-md-3"
            data-aos="fade-down"
            data-aos-duration="1000"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
              transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
              transform: isHovered ? "scale(1.1)" : "scale(1)",
              opacity: isHovered ? 1 : 0.9, // Adjust opacity values as needed
            }}
          >
            <div className="icon-container" >
              {(isHovered ? <img
                src={developer}
               
                style={{ borderRadius: "20px", width: 120, objectFit: "cover" , backgroundColor: "" }}
                alt="Seasoned Developers"
                className="icon-container"
              /> : <i className="fa fa-users"></i>
              )}
            </div>
            <h3>Seasoned Developers</h3>
            <p style={{textAlign: "left" ,margin:10}} >
              Our developers possess exceptional programming skills and stay
              updated on the latest advancements in the tech world.
            </p>
          </div>



          <div
            className="col-xs-12 col-sm-12 col-md-3 "
            data-aos="fade-down"
            data-aos-duration="1000"
            onMouseEnter={() => setIsHovered2(true)}
            onMouseLeave={() => setIsHovered2(false)}

            style={{
              transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
              transform: isHovered2 ? "scale(1.1)" : "scale(1)",
              opacity: isHovered2 ? 1 : 0.9, // Adjust opacity values as needed
            }}

          >



            <div className="icon-container"


            >



              {(isHovered2 ? <img
                src={solution}
              
                style={{ borderRadius: "20px", backgroundColor: ""  ,width: 120, objectFit: "cover" }}
                alt="Seasoned Developers"
                className="icon-container"
              /> : <i className="fa fa-sitemap"></i>

              )}
            </div>

            <h3>Astute Solution Architects</h3>
            <p style={{textAlign: "left" ,margin:10}} >

              Our architects excel at detail-oriented, innovative solutions aligned with your strategic objectives
            </p>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-3 developer-card"
            data-aos="fade-down"
            data-aos-duration="1000"
            onMouseEnter={() => setIsHovered3(true)}
            onMouseLeave={() => setIsHovered3(false)}

            style={{
              transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
              transform: isHovered3 ? "scale(1.1)" : "scale(1)",
              opacity: isHovered3 ? 1 : 0.9, // Adjust opacity values as needed
            }}

          >

            <div className="icon-container">



              {(isHovered3 ? <img
                src={house}
               
                style={{ borderRadius: "20px", backgroundColor: ""  ,width: 120, objectFit: "cover"  }}
                alt="Seasoned Developers"
                className="icon-container"
              /> : <i className="fa fa-thumbs-up"></i>

              )}
            </div>







            <h3>IT Infrastructure Specialists</h3>
            <p style={{textAlign: "left" ,margin:10}} >
              Our team ensures the seamless operation and maintenance of your IT
              infrastructure, keeping your systems running smoothly.
            </p>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-3 developer-card"
            data-aos="fade-down"
            data-aos-duration="1000"
            onMouseEnter={() => setIsHovered4(true)}
            onMouseLeave={() => setIsHovered4(false)}

            style={{
              transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
              transform: isHovered4 ? "scale(1.1)" : "scale(1)",
              opacity: isHovered4 ? 1 : 0.9, // Adjust opacity values as needed
            }}
          >

            <div className="icon-container">



              {(isHovered4 ? <img
                src={manager}
               
                style={{ borderRadius: "20px", backgroundColor: ""   ,width: 120, objectFit: "cover" }}
                alt="Seasoned Developers"
                className="icon-container"
              /> : <i className="fa fa-shield"></i>

              )}
            </div>





            <h3>Dedicated Account Managers</h3>
            <p style={{textAlign: "left" ,margin:10}}>
              We assign a dedicated account manager to each project, ensuring
              clear communication and exceptional service.
            </p>
          </div>
        </Card>
      </div>


    </div>
  );
};
