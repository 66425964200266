import { useState, useEffect } from 'react'

import JsonData from '../../../data/data.json'
import { Navigation } from '../../navigation'
import Footer from '../../footer'
import { Consultingheader } from './consultingheader'

import {WhyChoose } from './whychoose'
import Timeline from './timeline'
import {Consabout } from './consabout'
import {Contactcons } from './contactcons'
const Consultingfirstpage = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
    window.scrollTo(0, 0);
  }, [])

  return (
    <div >
   <Navigation></Navigation>
<Consultingheader></Consultingheader>
<Consabout/>
<WhyChoose></WhyChoose> 
<Timeline/>


<Contactcons/>

<Footer/>

    </div>
  )
}

export default Consultingfirstpage
