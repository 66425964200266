import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import developer from "../../../assets/itservices/programmer.gif"
import solution from "../../../assets/itservices/sleep.gif"
import house from "../../../assets/itservices/champion.gif"
import manager from "../../../assets/itservices/truck.gif"
import money from "../../../assets/itservices/rupee.gif"
import bgimage from "../../../assets/bgforitser.jpg"
import { Card } from "@mui/material";

export const CompleteContent = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

 
  return (
    <div id="features" className="text-center"  style={{ backgroundImage: `url(${bgimage})`, backgroundSize: "cover"}}>

      <div   className="container" data-aos="fade-up">
      <Card  style={{    borderRadius:30, marginBottom:60}}  >
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2  >Why Choose RedRuby for Your Complete IT Needs?
</h2>
         
        </div>

        <div
      className="col-xs-12 col-md-3"
      data-aos="fade-down"
      data-aos-duration="1000"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
        transform: isHovered ? "scale(1.1)" : "scale(1)",
        opacity: isHovered ? 1 : 0.9, 
      }}
    >
      <div className="icon-container">

    
        
        { (isHovered  ?  <img
            src={developer}
            width={100}
            style={{borderRadius:"20px" , backgroundColor:"" , width: 120, objectFit: "cover"}}
            alt="Operational Excellence"
            className="icon-container"
          /> : <i className="fa fa-users"></i>  
         
        )}
      </div>
      <h3>Operational Excellence</h3>
      <p style={{ textAlign: "left"  , margin:10}}>
      We handle IT system reliability, so you focus on your business.
      </p>
    </div>
        <div
          className="col-xs-12 col-md-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered2(true)}
      onMouseLeave={() => setIsHovered2(false)}
      style={{
        transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
        transform: isHovered2 ? "scale(1.1)" : "scale(1)",
        opacity: isHovered2 ? 1 : 0.9, // Adjust opacity values as needed
      }}
        >

         

 <div className="icon-container">

    
        
        { (isHovered2  ?  <img
            src={solution}
            width={100}
            style={{borderRadius:"20px" , backgroundColor:"", width: 120, objectFit: "cover"}}
            alt="Seasoned Developers"
            className="icon-container"
          /> :  <i className="fa fa-sitemap"></i>
         
        )}
      </div>

          <h3>24/7 Support</h3>
          <p style={{ textAlign: "left"  , margin:10}}>
          Our IT team is always on call to help, 24/7
          </p>
        </div>
        <div
          className="col-xs-12 col-md-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered3(true)}
          onMouseLeave={() => setIsHovered3(false)}
          style={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered3 ? "scale(1.1)" : "scale(1)",
            opacity: isHovered3 ? 1 : 0.9, // Adjust opacity values as needed
          }}
        >

<div className="icon-container">

    
        
{ (isHovered3  ?  <img
    src={house}
    width={100}
    style={{borderRadius:"20px" , backgroundColor:"", width: 120, objectFit: "cover"}}
    alt="Seasoned Developers"
    className="icon-container"
  /> :   <i className="fa fa-thumbs-up"></i>
 
)}
</div>

         





          <h3>Expert Guidance</h3>
          <p style={{ textAlign: "left"  , margin:10}}>
          Our team will assess your needs and advise on optimal IT.
          </p>
        </div>
        <div
          className="col-xs-12 col-md-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered4(true)}
          onMouseLeave={() => setIsHovered4(false)}
          style={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered4 ? "scale(1.1)" : "scale(1)",
            opacity: isHovered4 ? 1 : 0.9, // Adjust opacity values as needed
          }}
        >

<div className="icon-container">

    
        
{ (isHovered4  ?  <img
    src={manager}
    width={100}
    style={{borderRadius:"20px" , backgroundColor:"", width: 120, objectFit: "cover"}}
    alt="Scalable Solutions"
    className="icon-container"
  /> : <i className="fa fa-shield"></i>
 
)}
</div>

          



          <h3>Scalable Solutions</h3>
          <p style={{ textAlign: "left"  , margin:10}}>
          We provide adaptable IT services to match your evolving needs.
          </p>
        </div>

        
      
        </Card>
      </div>


    </div>
  );
};
