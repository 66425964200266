






import about from "../../../assets/imageforabout/businesserp.jpg"
import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
export const Odoobigpage = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
     
    });
  }, []);
  return (
    <div id='about12'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'  >
            {' '}
            <img style={{height:300 , borderRadius:20 }}  data-aos='fade-up' src={about} className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'  data-aos='fade-up'>
            <div className='about-text'>
              <h2 style={{color:"white"}}>What is Unity ERP</h2>
              <p style={{color:"white"}}  > RedRuby Technologies is proud to offer customized Unity ERP solutions based on Odoo framework tailored to the specific needs of government and private sector organizations. Unity ERP is a comprehensive, open-source platform designed to centralize and optimize your business processes.
</p>
           
            

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
