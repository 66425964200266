






import about from "../../../assets/imageforabout/illustration.jpg"
import React, { useEffect  , useState} from 'react';
import EnquiryDialog from "../../dialog";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
export const Cybercontact = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
     
    });
  }, []);



  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleEnquirySubmit = (formData) => {
    console.log("Submitted:", formData);
    // Add your form submission logic here
  };
  return (
    <div id='about123'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-12'  >
            {' '}
           
          </div>  <h2   >Embrace a Secure Future with Cyber Kavalan

</h2>
          
          



          
              <p  > <strong>Don't wait for a cyberattack to cripple your operations. RedRuby Technologies' Cyber Kavalan offers a comprehensive and cost-effective solution that safeguards your digital assets and empowers your workforce. </strong>  <br/>  <br/> Contact us today to learn more about Cyber Kavalan and build a robust shield against cyber threats!
</p>
           
 
<div style={{marginBottom:20  , display:"flex"   , justifyContent:"center", alignItems:"center"}} className="px-5 py-4 pb-5">
                <a href="/careers" > <button  type="submit" style={{ fontWeight:"bold" }} data-mdb-button-init data-mdb-ripple-init  className='btn btn-custom btn-lg page-scroll' data-aos='fade-up'>Get In Touch With Us</button> </a>    
                  </div>
             
        </div>
      </div>
      <EnquiryDialog isOpen={isDialogOpen} onClose={handleDialogClose} onSubmit={handleEnquirySubmit} />
    </div>
  )
}
