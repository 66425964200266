






import about from "../../assets/imageforabout/computersmartphone.jpg"
import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
export const Infraabout = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
     
    });
  }, []);
  return (
    <div id='about12'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'  >
            {' '}
            <img style={{height:400 , borderRadius:30  }}  data-aos='fade-up' src={about} className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'  data-aos='fade-up'>
            <div className='about-text'>
              <h2 style={{color:"white"}}   >Pulse for your Infrastructure management
</h2>
              <p style={{color:"white"}}  > RedRuby Technologies understands the critical role of robust infrastructure in today's digital landscape. That's why we offer a powerful suite of Pulse solutions designed to streamline.
</p>
<div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                   
                     
                          <li style={{fontWeight:500,color:"white"}}>Asset Management</li>
                          <li style={{fontWeight:500,color:"white"}} >Network Monitoring</li>
                          <li  style={{fontWeight:500,color:"white"}} >IT service delivery</li>
                         
                        
                   
                  </ul>
                </div></div>
            

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
