import React, { useEffect, useRef , useState } from "react";
import "../../src/components/footer.css";

import gsap from "gsap";
import EnquiryDialog from "./dialog";

function Footer() {
  const currentYear = new Date().getFullYear();
  const mapEmbedUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.8415959162053!2d80.26995375568859!3d13.04575292415658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267d980752e43%3A0x8309e1aff33805bc!2sRedRuby%20Technologies!5e0!3m2!1sen!2sin!4v1714048310478!5m2!1sen!2sin";

  const mapRef = useRef(null);
  const addressRef = useRef(null);
  const emailRef = useRef(null);

  
  
  const waveAnimation = (element, delay) => {
    gsap.fromTo(
      element,
      { y: -10, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        delay,
        ease: "power3.out",
      }
    );
  };

  useEffect(() => {

    waveAnimation(mapRef.current, 0);
    waveAnimation(addressRef.current, 0.2);
    waveAnimation(emailRef.current, 0.4);
  }, []);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleEnquirySubmit = (formData) => {
    console.log("Submitted:", formData);
    // Add your form submission logic here
  };



  return (
    <footer id="footers" class="footer-1">
<div class="main-footer widgets-dark typo-light">
<div class="container">
<div class="row">
  
<div class="col-xs-12 col-sm-6 col-md-12">
<div class="widget subscribe no-box">
<h5 class="widget-title">RedRuby Technologies<span></span></h5>
<div className="map-container">
              <iframe
                title="Map"
                width="100%"
                height="300"
                frameBorder="0"
                style={{ border: 0 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                src={mapEmbedUrl}
                allowFullScreen
                ref={mapRef} // Add ref here
              ></iframe>
            </div>
           
</div>
</div>

<div class="col-xs-12 col-sm-6 col-md-3">
<div class="widget no-box">
<h5 class="widget-title">Quick Links<span></span></h5>
<ul class="thumbnail-widget">
<li>
<div class="thumb-content">  <a
                        href="/#about"
                        style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")}
                      >About </a></div> 
</li>
<li
>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")} href="/#features">Talent</a></div> 
</li>
<li>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")} href="/#values">Values</a></div> 
</li>
<li>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")} href="/technologies">What We do ?</a></div> 
</li>

<li>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")} href="/privacy-policy">Privacy Policy</a></div> 
</li><li>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                          cursor: "pointer"
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")}
                        
                    
                        href="/careers"     
                        >   For Enquiry</a></div> 
</li>

</ul>
</div>
</div>
<div class="col-xs-12 col-sm-6 col-md-3">
<div class="widget no-box">
<h5 class="widget-title">services<span></span></h5>
<ul class="thumbnail-widget">
<li>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")} href="/itservices">Complete IT services</a></div> 
</li>
<li>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")} href="/kavalan">Cyber Kavalan</a></div> 
</li>
<li>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")} href="/consulting">Consulting Services</a></div> 
</li>



</ul>
</div>
</div>

<div class="col-xs-12 col-sm-6 col-md-3">
<div class="widget no-box">
<h5 class="widget-title">Products<span></span></h5>
<ul class="thumbnail-widget">
<li>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")} href="/gyanbuddy">GyanBuddy KMS</a></div> 
</li>
<li>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")} href="/unityerp">Unity ERP Solutions</a></div> 
</li>
<li>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")} href="/facetag">Face-Tag</a></div> 
</li>
<li>
<div class="thumb-content"><a  style={{
                          color: "white",
                          transition: "color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "orange")}
                        onMouseLeave={(e) => (e.target.style.color = "white")} href="/infrastructure">Infrastructure Solutions</a></div> 
</li>



</ul>
</div>
</div>


<div class="col-xs-12 col-sm-6 col-md-3">

<div class="widget no-box">
<h5 class="widget-title">Contact Us<span></span></h5>
<ul class="thumbnail-widget">
{/* Address : 19/1, 8th St, Jagadambal Colony, Durgapuram, Mylapore, Chennai, Tamil Nadu 600004 */}
  <li><div class="thumb-content1">
                Address : New No. 2, Old No. 7, North Crescent Road, Lakshmi Colony, GN Chetty Road, T.Nagar, Chennai, Tamil Nadu 600017
             </div></li>



</ul>
</div>

</div>

</div>
</div>
</div>
  
<div class="footer-copyright">
<div class="container">
<div class="row">
<div class="col-md-12 text-center">
<p>Copyright  © {currentYear} RedRuby Technologies. All rights reserved.</p>
</div>
</div>
</div>
</div>
<EnquiryDialog isOpen={isDialogOpen} onClose={handleDialogClose} onSubmit={handleEnquirySubmit} />
</footer>
 
);
}

export default Footer;
