import React, { useEffect } from 'react';
import ParticlesBg from 'particles-bg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const getRandomParticleType = () => {
  const types = ["color", "ball", "lines", "thick", "circle", "cobweb", "polygon", "square"];
  return types[Math.floor(Math.random() * types.length)];
};

export const NotFound = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const particleType = getRandomParticleType();

  return (
    <header>
      <div style={{ backgroundColor: '#2C5CDF' }} className='intro' data-aos='fade-up'>
        <ParticlesBg
          type={particleType}
          bg={{ zIndex: 0, position: 'absolute', top: 0, width: '100%' }}
        />
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div
                className='col-md-8 col-md-offset-2 intro-text'
                data-aos='fade-up'
                data-aos-delay='300'
                style={{ textAlign: 'center', color: '#fff', paddingTop: '20%' }}
              >
                <h1>404</h1>
                <h2 style={{ color: 'white' }}>Not Found</h2>
                <p>The page you are looking for does not exist.</p>
                <a href="/" className="btn btn-primary">Go Back to Home</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
