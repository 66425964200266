





import React, { useEffect , useState } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
import EnquiryDialog from '../dialog';
export const InfraContact = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
     
    });
  }, []);
  const [isDialogOpen, setIsDialogOpen] = useState(false);


  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleEnquirySubmit = (formData) => {
    console.log("Submitted:", formData);
  };

  return (
    <div id='about123'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-12'  >
            {' '}
           
          </div>  <h2  >Ready to elevate your infrastructure with Pulse Solutions? 
</h2>
          
          


       
              <p  > <strong> RedRuby Technologies is here to help. Our team of experts is dedicated to providing you with the best tools and support to optimize your asset management, network monitoring, and IT service delivery.
    </strong> <br/> 
    Don’t wait to optimize your infrastructure. Get in touch with RedRuby Technologies today and discover the difference Pulse Solutions can make!</p>
           
 
<div style={{marginBottom:20  , display:"flex"   , justifyContent:"center", alignItems:"center"}} className="px-5 py-4 pb-5">
                <a href="/careers" > <button  type="submit" style={{ fontWeight:"bold" }} data-mdb-button-init data-mdb-ripple-init  className='btn btn-custom btn-lg page-scroll' data-aos='fade-up'>Get In Touch With Us</button> </a>    
                  </div>
             
        </div>
      </div>
      <EnquiryDialog isOpen={isDialogOpen} onClose={handleDialogClose} onSubmit={handleEnquirySubmit} />
    </div>
  )
}
