import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import image1 from  "../../../../assets/school.jpg";
import image2 from  "../../../../assets/org.jpg";
import image3 from  "../../../../assets/remote.jpg";

export const Attenpics = (props) => {

  const data = [
    { thumb: image1, title: "Schools", subtitle: "Ensure student safety and streamline attendance processes" },
    { thumb: image2, title: "Organizations", subtitle: " Boost employee engagement and improve timekeeping accuracy" },
    { thumb: image3, title: "Remote Workforces", subtitle: "Monitor attendance efficiently, even for geographically dispersed teams"
     },
   
   

  ];

  const images = data.map((obj) => obj.thumb.replace("-small", "-large"));


  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Face Tag AMS caters to a wide range of requirements, making it ideal for...</h2>

        </div>
        <div className="row">
          <div className="portfolio-items">
            {data.map(({ title, thumb, subtitle }, index) => (
              <div key={index} className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item " style={{ height: "300px", width: "100%", overflow: "hidden" }}>
                  <div className="hover-bg">
                    <div className="hover-text">
                   
                      <p style={{color:"white"}} >{subtitle}</p>
                    </div>

                 

                    <img
                      src={thumb}
                      style={{ height: "100%", width: "100%", objectFit: "cover" }}
                      className="img-responsive"
                      alt="Project Title"
                    />
                               
                  </div>
                </div>
                <h4>{title}</h4>      
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
};
