import { useState, useEffect } from 'react'

import JsonData from '../../../data/data.json'

import { Navigation } from '../../navigation'

import Footer from '../../footer'

import {Cyberheader} from './cyberheader'

import {MultiLayered} from './multilayered'
import {WhyChoose} from './whychoose'
import {Cybercontact} from './cybercontact'
import Embrace from './embrace'

import {Cyberabout} from './cyberkavalanabout'
const CyberKavalanIndex = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
    window.scrollTo(0, 0);
  }, [])

  return (
    <div >
   <Navigation></Navigation>
     <Cyberheader/>
<Cyberabout/>
     <WhyChoose></WhyChoose>
     <MultiLayered></MultiLayered>

     {/* <Embrace></Embrace> */}
     <Cybercontact/>
    <Footer/>
    </div>
  )
}

export default CyberKavalanIndex
