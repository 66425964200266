






import about from "../../assets/icons/app-development.png"
import React, { useEffect } from 'react';
import { Card } from "@mui/material";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Mobilepara = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
      <Card>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'  >
            {' '}
            <img style={{height:300  }}  data-aos='fade-right' src={about} className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'  data-aos='fade-left'>
            <div className='about-text'>
              <h2>Custom Mobile App Development</h2>

             


              <p>  Imagine having a powerful mobile app that perfectly complements your business strategy
</p>
           <p>Our skilled developers bring your mobile app ideas to life, crafting user-friendly and feature-rich applications for iOS and Android platforms.
</p>

           
            </div>
          </div>
        </div>
      </div>
    </Card>
    </div>
  )
}

export default  Mobilepara