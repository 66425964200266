import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

export const Services = (props) => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  useEffect(() => {
    const hoverAnimation = (ref) => {
      gsap.to(ref.current.querySelector('.card-img-top'), {
        scale: 1.2,
        duration: 0.9,
        onComplete: () => {
          gsap.set(ref.current, { scale: 1 });
        },
      });
    };

    const unhoverAnimation = (ref) => {
      gsap.to(ref.current.querySelector('.card-img-top'), {
        scale: 1,
        duration: 0.5,
      });
    };

    const addHoverListeners = (ref) => {
      if (ref.current) {
        ref.current.addEventListener("mouseenter", () => hoverAnimation(ref));
        ref.current.addEventListener("mouseleave", () => unhoverAnimation(ref));
      }
    };

    const removeHoverListeners = (ref) => {
      if (ref.current) {
        ref.current.removeEventListener("mouseenter", () => hoverAnimation(ref));
        ref.current.removeEventListener("mouseleave", () => unhoverAnimation(ref));
      }
    };

    addHoverListeners(ref1);
    addHoverListeners(ref2);
    addHoverListeners(ref3);

    return () => {
      removeHoverListeners(ref1);
      removeHoverListeners(ref2);
      removeHoverListeners(ref3);
    };
  }, []);

  const data = [
    { thumb: "img/portfolio/itservice.jpg", link: "/itservices", title: "Complete IT services", subtitle: " IT services designed to keep your systems running smoothly, securely, and efficiently." },
    { thumb: "img/portfolio/cyberlock.jpg", link: "/kavalan", title: "Cyber security Services", subtitle: "One-stop solution for impenetrable network defense and proactive threat mitigation." },
    { thumb: "img/portfolio/consultingservice.jpg", link: "/consulting", title: "Consulting services", subtitle: "Navigating the complexities of digital migration, or seeking expert advice on any technology-related challenge." },
  ];

  const containerRef = useRef(null);

  // const scrollTo = (y) => {
  //   gsap.to(containerRef.current, { duration: 0.5, scrollTo: y, ease: "power2.inOut" });
  // };

  const gradients = [
    'linear-gradient(to down, #B0E0E6, #2C5CDF)',
  ];

  const [randomGradient, setRandomGradient] = useState('');
  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * gradients.length);
      setRandomGradient(gradients[randomIndex]);
    }, 500);

    return () => clearInterval(intervalId);
  }, [gradients]);

  return (
    <div id="team" className="text-center">
      <div className="container" ref={containerRef}>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>services</h2>
        </div>

        <div className="row">
          {data.map(({ title, thumb, subtitle, link }, index) => (
            <div key={index} className="col-sm-6 col-md-4 col-lg-4 mt-4">
              <div className="card card-inverse card-info  d-flex flex-column h-100" ref={ref => (index === 0 ? ref1.current = ref : index === 1 ? ref2.current = ref : ref3.current = ref)}>
                <Link to={link} className="service-link">
                  <div className="img-wrapper">
                    <img className="card-img-top" src={thumb} alt={title} />
                  </div>
                </Link>
                <div className="card-block">
                  <h4 className="card-title">{title}</h4>
                  <div className="card-text">{subtitle}</div>
                </div>
                <div className="card-footer mt-auto"> <Link to={link} className="service-link">
                  <button style={{ backgroundImage: randomGradient, fontWeight: "bold" }} className="btn btn-custom btn-sm page-scroll" data-aos="fade-up">
                    {"Explore"}
                  </button></Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


{/* <p><a href="mailto:info@domain.com" title="glorythemes">info@domain.com</a></p> */}
