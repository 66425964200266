import {  useEffect } from 'react'
import { Navigation } from './navigation'







import Footer from './footer'
import PrivacyPolicyPage from './privacypolicypage'





const Privacyfirst = () => {

 

  return (

    <div>

    <Navigation></Navigation>
     
    

       <PrivacyPolicyPage/>
       <Footer />
     </div>



  )
}

export default Privacyfirst
