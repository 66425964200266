import { useState, useEffect } from 'react'

import JsonData from '../../../../data/data.json'


import { Navigation } from '../../../navigation'

import { Docheader } from './docheader'
import { Docabout } from './docabout'
import { Docbenifits } from './docben'
import Footer from '../../../footer'

import { Attencon } from './doccon'
import { GyanContact } from './gyancontact'


const Docfirstpage = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
    window.scrollTo(0, 0);
  }, [])

  return (
    <div >
   <Navigation></Navigation>
      <Docheader/>
    <Docabout></Docabout>

    <Docbenifits></Docbenifits>
    <Attencon/>
    <GyanContact> </GyanContact>
    <Footer/>
    </div>
  )
}

export default Docfirstpage
