import React, { useEffect } from 'react';
import ParticlesBg from 'particles-bg';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Cyberheader = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
  
    });
  }, []);

  return (
    <header >
         <div style={{ backgroundColor: 'white' }} className='intro' data-aos='fade-up'>
        <ParticlesBg
          type='cobweb'
          color='#2B2A4C'
          num={300}
          bg={{ zIndex: 0, position: 'absolute', top: 0 }}
      />
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div
                className='col-md-8 col-md-offset-2 intro-text'
                data-aos='fade-up'
                data-aos-delay='300'
              >
                <h2 style={{color:"#000000"}} >Cyber  Kavalan</h2>
                <div>
                  <h4 style={{ color: '#000000' }}>Cyber Security Services
</h4>
                 
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
