






import React, { useEffect } from 'react';
import jmeter from "../../assets/tech/jmeter.png"
import selenium from "../../assets/tech/selenium.png"
import junit from "../../assets/tech/junit.png"
import appium from "../../assets/tech/appium.jpeg"

import burpsuite from "../../assets/tech/burpsuite.png"

import locust from "../../assets/tech/locust.jpeg"
import katalon from "../../assets/tech/katalon.png"
import cucumber from "../../assets/tech/cucumber.png"
import neses from "../../assets/tech/nessus.png"

import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Testing = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
      <div className='container'>
        <div className='row'>
        <h2>Testing and Quality management
</h2>
          <div className='col-xs-12 col-md-10'  >
            {' '}
         <div style={{ margin:30, display: 'flex', flexWrap: 'wrap'  , justifyContent:'space-evenly' }}   >  
         
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       
         <img  style={{height:150 , width:150  }}  data-aos='fade-up' src={jmeter} className='grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>JMETER </p>
       </div>  
         
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={selenium} className='img-responsive grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>SELENIUM </p>
       </div> 
         
         
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={junit} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>JUNIT </p>
       </div> 
         

       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       
       <img  style={{height:150 , width:150  }}  data-aos='fade-up' src={appium} className='grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>APPIUM </p>
       </div>    
            
         
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={burpsuite} className='img-responsive grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>BURPSUITE </p>
       </div>

       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={cucumber} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>CUCUMBER </p>
       </div> 
            
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={neses} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>NESSUS </p>
       </div>
          
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={locust} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>LOCUST </p>
       </div> 
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={katalon} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>KATALON </p>
       </div> 
          
          




             </div>
       
       
          </div>
          <div className='col-xs-12 col-md-'  data-aos='fade-up'>
            <div className='about-text'>

           
<p>We use a range of testing techniques and technologies to ensure everything we release operates as intended.






</p>
         
             
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default  Testing