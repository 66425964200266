import React, { useEffect, useState } from "react";
import developer from "../../../assets/cyberkalvangif/phone.gif"
import solution from "../../../assets/cyberkalvangif/box.gif"
import house from "../../../assets/cyberkalvangif/giphy.gif"
import manager from "../../../assets/cyberkalvangif/code.gif"
import AOS from "aos";
import "aos/dist/aos.css";

export const MultiLayered = (props) => {

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div id="features" className="text-center" style={{overflow:"hidden"}}>
      <div className="container" data-aos="fade-up">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>
            A Multi-Layered Shield

          </h2>

        </div>

        <div
          className="col-xs-12 col-md-6 col-lg-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered ? "scale(1.1)" : "scale(1)",
            opacity: isHovered ? 1 : 0.9, // Adjust opacity values as needed
          }}
        >

          <div className="icon-container">



            {(isHovered ? <img
              src={developer}
              // width={110}
              style={{ borderRadius: "20px", width: 160, objectFit: "cover" }}
              alt="Seasoned Developers"
              className="icon-container"
            /> : <i className="fa fa-desktop"></i>

            )}
          </div>




          <h3>End-Device Security Management</h3>
          <p style={{ textAlign: "left", margin:10 }}>
          We use a powerful tool called Wazuh to keep your devices safe. It constantly watches for any unusual activity, detects intrusions, and manages vulnerabilities in real-time.  </p>
        </div>
        <div
          className="col-xs-12 col-md-6 col-lg-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
          style={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered2 ? "scale(1.1)" : "scale(1)",
            opacity: isHovered2 ? 1 : 0.9, // Adjust opacity values as needed
          }}
        >


          <div className="icon-container">



            {(isHovered2 ? <img
              src={solution}
              // width={110}
              style={{ borderRadius: "20px", width: 120, objectFit: "cover" }}
              alt="Seasoned Developers"
              className="icon-container"
            /> : <i className="fa fa-shield"></i>

            )}
          </div>








          <h3>Network Security Management</h3>
          <p style={{ textAlign: "left", margin:10 }}>
          We use top-notch security techniques recommended by NIST to regularly check for any weak spots in your network. By doing this, we catch and fix any potential vulnerabilities before they become a problem.   </p>
        </div>
        <div
          className="col-xs-12 col-md-6 col-lg-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered3(true)}
          onMouseLeave={() => setIsHovered3(false)}
          style={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered3 ? "scale(1.1)" : "scale(1)",
            opacity: isHovered3 ? 1 : 0.9, // Adjust opacity values as needed
          }}
        >

          <div className="icon-container">



            {(isHovered3 ? <img
              src={house}
              // width={170}
              style={{ borderRadius: "20px", width: 120, objectFit: "cover" }}
              alt="Seasoned Developers"
              className="icon-container"
            /> : <i className="fa fa-user-secret"></i>


            )}
          </div>







          <h3>Penetration Testing & Auditing</h3>
          <p style={{ textAlign: "left" , margin:10 }}>
          We dig deep into your system with penetration testing, mimicking real attacks to find weak spots. This helps us fix vulnerabilities and make your defenses stronger.    </p>
        </div>
        <div
          className="col-xs-12 col-md-6 col-lg-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered4(true)}
          onMouseLeave={() => setIsHovered4(false)}
          style={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered4 ? "scale(1.1)" : "scale(1)",
            opacity: isHovered4 ? 1 : 0.9, // Adjust opacity values as needed
          }}
        >

          <div className="icon-container">



            {(isHovered4 ? <img
              src={manager}
              // width={110}
              style={{ borderRadius: "20px", width: 120, objectFit: "cover" }}
              alt="Seasoned Developers"
              className="icon-container"
            /> : <i className="fa fa-users"></i>

            )}
          </div>





          <h3>Empowering Your Workforce</h3>
          <p style={{ textAlign: "left"  , margin:10}}>
            Knowledge is power, especially in cybersecurity. Cyber Kavalan includes specialized training for your employees, equipping them with the tools and awareness to recognize and avoid cyber threats.
          </p>
        </div>
      </div>
    </div>
  );
};
