import { Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

export const WhyChoose = () => {

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  useEffect(() => {
    const hoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1.3,
        duration: 0.2,
        onComplete: () => {
          gsap.set(ref, { scale: 1 });
        },
      });
    };

    const unhoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1,
        duration: 0.1,
      });
    };

    const addHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.addEventListener("mouseenter", () => hoverAnimation(element));
        element.addEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    const removeHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.removeEventListener("mouseenter", () => hoverAnimation(element));
        element.removeEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    addHoverListeners(ref1);
    addHoverListeners(ref2);
    addHoverListeners(ref3);
    addHoverListeners(ref4);
    addHoverListeners(ref5);
    return () => {
      removeHoverListeners(ref1);
      removeHoverListeners(ref2);
      removeHoverListeners(ref3);
      removeHoverListeners(ref4);
      removeHoverListeners(ref5);
    };
  }, []);

  return (
    <div id='services' className='text-center' style={{overflow:"hidden"}}>
      <div className='container'>
        <div className='section-title' data-aos='fade-up'>
          <h2>Why Choose RedRuby for Your Consulting Needs?</h2>

       
        </div>
        <div className='row' data-aos='fade-up'

        >
          <div ref={ref1} className='col-md-4'>
         


          <i class="fa  fa-star" aria-hidden="true"></i>



              <div className='service-desc' data-aos='fade-up'>
                <h3>Extensive Expertise</h3>
                <p>Our consultants possess a wide range of knowledge across various technological domains.</p>
              </div>
       
          </div>

          <div ref={ref2} className='col-md-4'>
           
          <i class="fa fa-connectdevelop" aria-hidden="true"></i>

          {/* <i class="fa fa-server" aria-hidden="true"></i> */}
              
              <div className='service-desc' data-aos='fade-up'>
                <h3>Unbiased Recommendations</h3>
                <p>We focus on providing solutions that align with your unique needs and budget, not on promoting specific vendors or products.

</p>
              </div>
          
          </div>

          <div ref={ref3} className='col-md-4'>
            
              <i className="fa fa-sitemap"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Proven Track Record</h3>
                <p>We have a successful history of helping clients bring their projects to life.
</p>
              </div>
        
          </div>
          <div ref={ref4} className='col-md-6'>
           
          <i class="fa  fa-cogs" aria-hidden="true"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Collaborative Approach</h3>
                <p>We believe in working transparently and collaboratively with our clients.</p>
              </div>
      
          </div>
          <div ref={ref5} className='col-md-6'>
           
           <i class="fa fa-crosshairs" aria-hidden="true"></i>
               <div className='service-desc' data-aos='fade-up'>
                 <h3>Focus on Value</h3>
                 <p>Our goal is to ensure you achieve a return on your investment and maximize project benefits.</p>
               </div>
       
           </div>
        </div>
      </div>
    </div>
  );
}

