import { useState, useEffect } from 'react'

import JsonData from '../../data/data.json'
import { Navigation } from '../navigation'
import Footer from '../footer'
import { Infraheader } from './infraheader'
import { Infracontent } from './infracontent'

import {PulseAIOps } from './AIOps'
import { Infrapic } from './pic'
import { Infraabout } from './infraabout'
import { InfraContact } from './infracontact'

const Infrafirstpage = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
    window.scrollTo(0, 0);
  }, [])

  return (
    <div >
   <Navigation></Navigation>
<Infraheader></Infraheader>
<Infraabout/>
<Infracontent></Infracontent><PulseAIOps></PulseAIOps> 

<Infrapic></Infrapic>
<InfraContact></InfraContact>
<Footer/>

    </div>
  )
}

export default Infrafirstpage
