import { Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

export const Product = (props) => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  useEffect(() => {
    const hoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1.3,
        duration: 0.2,
        onComplete: () => {
          gsap.set(ref, { scale: 1 });
        },
      });
    };

    const unhoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1,
        duration: 0.1,
      });
    };

    const addHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.addEventListener("mouseenter", () => hoverAnimation(element));
        element.addEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    const removeHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.removeEventListener("mouseenter", () => hoverAnimation(element));
        element.removeEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    addHoverListeners(ref1);
    addHoverListeners(ref2);
    addHoverListeners(ref3);
    addHoverListeners(ref4);

    return () => {
      removeHoverListeners(ref1);
      removeHoverListeners(ref2);
      removeHoverListeners(ref3);
      removeHoverListeners(ref4);
    };
  }, []);


  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Products</h2>
        </div>
        <div className="row" data-aos="fade-up">
          <Link to="/gyanbuddy">
            <div ref={ref1} className="col-md-4">
              <i className="fa fa-book"></i>

              <div className="service-desc" data-aos="fade-up">
                <h3>GyanBuddy KMS</h3>
                <p>Reimagine Knowledge Management with AI</p>
              </div>
            </div>
          </Link>

          <div ref={ref2} className="col-md-4">
            <Link to="/unityerp" className="service-link">
              <i className="fa fa-user"></i>
              <div className="service-desc" data-aos="fade-up">
                <h3>Unity ERP Solutions</h3>
                <p>Streamline Your Operations Across Sectors</p>
              </div>
            </Link>
          </div>
          <div ref={ref3} className="col-md-4">
            <Link to="/facetag" className="service-link">
              <i class="fa fa-graduation-cap" aria-hidden="true"></i>
              <div className="service-desc" data-aos="fade-up">
                <h3>Face-Tag</h3>
                <p>Streamlining attendance tracking for Organizations and Academics</p>
              </div>
            </Link>
          </div>
          <div ref={ref4} className="col-md-12">
            <Link to="/infrastructure" className="service-link">
              <i class="fa fa-globe" aria-hidden="true"></i>
              <div className="service-desc" data-aos="fade-up">
                <h3>Infrastructure Solutions</h3>
                <p>Empowering Your Infrastructure with Pulse Solutions</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
