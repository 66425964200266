import React, { useEffect } from 'react';
import ParticlesBg from 'particles-bg';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Infraheader = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
  
    });
    window.scroll(0,0);
  }, []);

  return (
    <header >
      <div style={{ backgroundColor: '#000000' }} className='intro' data-aos='fade-up'>
        <ParticlesBg
          type='square'
     num={50}
          bg={{ zIndex: 0, position: 'absolute', top: 0 }}
        />
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div
                className='col-md-8 col-md-offset-2 intro-text'
                data-aos='fade-up'
                data-aos-delay='300'
              >
                <h2 style={{color:"white"}} >Infrastructure Solutions
</h2>
                <div>
                  <h4 style={{ color: 'white' }}>Empowering Your Infrastructure with Pulse Solutions

</h4>

                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
