import React from "react";
import { Routes, Route } from "react-router-dom";
import Firstpage from "./firstpage";
import Odoofirstpage from "./components/servicepage/odoopage/odoofirst";
import Docfirstpage from "./components/servicepage/odoopage/docmitrapage/docfirstpage";
import PrivacyPolicyPage from "./components/privacypolicypage";
import Attenfirstpage from "./components/servicepage/odoopage/attendance/attendancefirstpage";
import Infrafirstpage from "./components/infra/infrafirstpage";
import CyberKavalanIndex from './components/servicepage/cyberKavalan/cyberkavalanindex'
import Consultingfirstpage from './components/servicepage/consulting/consultingfirstpage'
import Completefirstpage from './components/servicepage/complete/completefirstpage'

import Careersfirstpage from "./components/careers/careersfirstpage";
import TechIndex from "./components/tech/techfirstpage";
import Privacyfirst from "./components/privacyfisrtpage";
import { NotFound } from "./components/NotFound";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Firstpage />} />
        <Route path="/unityerp" element={<Odoofirstpage />} />
        <Route path="/gyanbuddy" element={<Docfirstpage />} />
        <Route path="/kavalan" element={<CyberKavalanIndex />} />   
        <Route path="/facetag" element={<Attenfirstpage />} />  
        <Route path="/infrastructure" element={<Infrafirstpage />} />    
    <Route path="/consulting" element={< Consultingfirstpage />} /> 
    <Route path="/itservices" element={< Completefirstpage />} /> 
    <Route path="/careers" element={< Careersfirstpage />} /> 
    <Route path="/technologies" element={< TechIndex />} /> 
    <Route path="/privacy-policy" element={<Privacyfirst />} />  
    <Route path="/technologies1" element={<   PrivacyPolicyPage />} /> 
    <Route path="*" element={<   NotFound />} /> 
    
     
      </Routes>
    </>
  );
};

export default App;
