import { useState, useEffect } from 'react'
import { Navigation } from '../navigation'
import Footer from '../footer'
import { Techheader } from './techheader'
import Carousel from './technologies'
import Frontend from './frontendtech'
import Frametech from './frametech'
import Mobiletech from './mobileapp'
import Cloudtech from './cloudtech'
import Webtech from './web'
import Datatech from './Datatech'
import Ana from './Ana'
import Api from './Api'
import Testing from './testing'
import Ecom from './ecom'
import Development from '../development'
import Mobilepara from './mobilepara'
import Webpara from './webpara'
import Conpara from './conpara'
import Digipara from './digipara'
import { TechContact } from './techcontact'
const TechIndex = () => {

  useEffect(() => {

    window.scrollTo(0, 0);
  }, [])

  return (
    <div >
   <Navigation></Navigation>
<Techheader/>
{/* <Development/> */}
<Mobilepara/>
     <Webpara/>
     <Conpara/>
     <Digipara/>
<Carousel/>
<Frontend/>
<Frametech/>
<Mobiletech/>
<Cloudtech/>
<Webtech/>
<Datatech/>
<Ana/>
<Api/>
<Testing/>
<Ecom/>
<TechContact></TechContact>
    <Footer/>
    </div>
  )
}

export default TechIndex
