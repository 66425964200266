import React, { useEffect, useState } from "react";
import developer from "../../../assets/gif/work.gif"
import solution from "../../../assets/gif/solved.gif"
import house from "../../../assets/gif/success.gif"
import manager from "../../../assets/gif/call.gif"
import AOS from "aos";
import "aos/dist/aos.css";

export const Odoovalues = (props) => {

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div id="features" className="text-center">
      <div className="container" data-aos="fade-up" style={{overflow:"hidden"}}>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>
            Why Choose RedRuby Technologies for Your Unity ERP Needs?
          </h2>

        </div>

        <div
          className="col-xs-12 col-md-6 col-lg-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered ? "scale(1.1)" : "scale(1)",
            opacity: isHovered ? 1 : 0.9,
          }}>

          <div className="icon-container">



            {(isHovered ? <img
              src={developer}
              style={{ borderRadius: "20px", width: 120, objectFit: "cover" }}

              alt="Seasoned Developers"
              className="icon-container"
            /> : <i className="fa fa-users"></i>

            )}
          </div>




          <h3>Deep Industry Expertise</h3>
          <p style={{ textAlign: "left" ,margin:10}}>
            We understand the specific needs and difficulties of government, education, HR, retail, and business sectors inside out.
          </p>
        </div>
        <div
          className="col-xs-12 col-md-6 col-lg-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
          style={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered2 ? "scale(1.1)" : "scale(1)",
            opacity: isHovered2 ? 1 : 0.9, // Adjust opacity values as needed
          }}
        >


          <div className="icon-container">



            {(isHovered2 ? <img
              src={solution}
              style={{ borderRadius: "20px", width: 120, objectFit: "cover" }}

              alt="Seasoned Developers"
              className="icon-container"
            /> : <i className="fa fa-cubes"></i>

            )}
          </div>








          <h3>Tailored Solutions</h3>
          <p style={{ textAlign: "left" ,margin:10}}>
            We don't offer a one-size-fits-all approach. We take the time to
            understand your specific needs and customize the Unity platform
            accordingly.
          </p>
        </div>
        <div
          className="col-xs-12 col-md-6 col-lg-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered3(true)}
          onMouseLeave={() => setIsHovered3(false)}
          style={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered3 ? "scale(1.1)" : "scale(1)",
            opacity: isHovered3 ? 1 : 0.9, // Adjust opacity values as needed
          }}>

          <div className="icon-container">



            {(isHovered3 ? <img
              src={house}
              style={{ borderRadius: "20px", width: 120, objectFit: "cover" }}

              alt="Seasoned Developers"
              className="icon-container"
            /> : <i className="fa fa-university"></i>


            )}
          </div>







          <h3>Seamless</h3>
          <p style={{ textAlign: "left",margin:10 }}>
            Our experienced team ensures a smooth and efficient Unity ERP
            implementation process, minimizing disruption to your daily
            operations.
          </p>
        </div>
        <div
          className="col-xs-12 col-md-6 col-lg-3"
          data-aos="fade-down"
          data-aos-duration="1000"
          onMouseEnter={() => setIsHovered4(true)}
          onMouseLeave={() => setIsHovered4(false)}
          style={{
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: isHovered4 ? "scale(1.1)" : "scale(1)",
            opacity: isHovered4 ? 1 : 0.9, // Adjust opacity values as needed
          }}>

          <div className="icon-container">



            {(isHovered4 ? <img
              src={manager}
              style={{ borderRadius: "20px", width: 120, objectFit: "cover" }}

              alt="Seasoned Developers"
              className="icon-container"
            /> : <i className="fa fa-shield"></i>

            )}
          </div>





          <h3>Ongoing Support</h3>
          <p style={{ textAlign: "left" ,margin:10}}>
            We provide ongoing support to ensure you get the most out of your
            Unity ERP system.
          </p>
        </div>
      </div>
    </div>
  );
};
