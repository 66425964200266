import React, { useEffect } from "react";
import "./privacy.css"

const PrivacyPolicyPage = () => {
    const currentYear = new Date().getFullYear();



    useEffect(() => {
       
        window.scrollTo(0, 0);
      }, [])
  return (
   
    <div className="privacy-policy-container">
    
        {/* <h1 className="pri-h1" >Privacy Policy</h1> */}


      <main className="pri-main" >
        <section className="pri-sec">
          <h2 className="pri-h2" >Responsible</h2>
          <p className="pri-p" >
            The person responsible for processing personal data collected during
            visits to this website is depending on the location you are coming
            from Chennai, India.
          </p>
        </section>
        <section  className="pri-sec"  >
          <h2  className="pri-h2"  >Who we are</h2>
          <p  className="pri-p" >Our website address is: https://redrubytechnologies.com.</p>
        </section>
        <section  className="pri-sec"  >
          <h2  className="pri-h2" >Area of application</h2>
          <  p   className="pri-p" >  
            This privacy policy is intended to inform the users of this website
            about the type, scope and purpose of the collection and use of
            personal data by the website operator RedRuby Technologies in
            accordance with the European Data Protection Act (GDPR). The website
            operator takes your data protection very seriously and treats your
            personal data confidentially and according to the legal regulations.
            Please keep in mind that data transfer on the internet can be
            subject to security gaps. A complete protection against access by
            strangers is not realizable.
          </p>
        </section>
        <section  className="pri-sec"   >
          <h2  className="pri-h2"   >Comments</h2>
          <p  className="pri-p" >
            When visitors leave comments on the site we collect the data shown
            in the comments form, and also the visitor’s IP address and browser
            user agent string to help spam detection.
          </p>
        </section>
        <section  className="pri-sec"  >
          <h2  className="pri-h2"  >Media</h2>
          <p  className="pri-p"  >
            If you upload images to the website, you should avoid uploading
            images with embedded location data (EXIF GPS) included. Visitors to
            the website can download and extract any location data from images
            on the website.
          </p>
        </section>
        <section  className="pri-sec"  >
          <h2  className="pri-h2"  >Cookies</h2>
          <p  className="pri-p" >
            If you leave a comment on our site you may opt-in to saving your
            name, email address and website in cookies. These are for your
            convenience so that you do not have to fill in your details again
            when you leave another comment. These cookies will last for one
            year.
          </p>
          <p className="pri-p" >
            When you log in, we will also set up several cookies to save your
            login information and your screen display choices. Login cookies
            last for two days, and screen options cookies last for a year. If
            you select “Remember Me”, your login will persist for two weeks. If
            you log out of your account, the login cookies will be removed.
          </p>

          <p className="pri-p"  >
            If you edit or publish an article, an additional cookie will be
            saved in your browser. This cookie includes no personal data and
            simply indicates the post ID of the article you just edited. It
            expires after 1 day.
          </p>
        </section>
        <section className="pri-sec" >
          <h2 className="pri-h2"  >Embedded content from other websites</h2>
          <p className="pri-p" >
            Articles on this site may include embedded content (e.g. videos,
            images, articles, etc.). Embedded content from other websites
            behaves in the exact same way as if the visitor has visited the
            other website.
          </p>
          <p className="pri-p" >
            These websites may collect data about you, use cookies, embed
            additional third-party tracking, and monitor your interaction with
            that embedded content, including tracking your interaction with the
            embedded content if you have an account and are logged in to that
            website.
          </p>
        </section>

        <section className="pri-sec"  >
          <h2 className="pri-h2"  >How long we retain your data</h2>
          <p className="pri-p" >
            If you leave a comment, the comment and its metadata are retained
            indefinitely. This is so we can recognize and approve any follow-up
            comments automatically instead of holding them in a moderation
            queue.
          </p>
          <p className="pri-p" >
            For users that register on our website (if any), we also store the
            personal information they provide in their user profile. All users
            can see, edit, or delete their personal information at any time
            (except they cannot change their username). Website administrators
            can also see and edit that information.
          </p>
        </section>
        <section className="pri-sec"    >
          <h2 className="pri-h2"  >What rights you have over your data</h2>
          <p className="pri-p" >
            If you have an account on this site, or have left comments, you can
            request to receive an exported file of the personal data we hold
            about you, including any data you have provided to us. You can also
            request that we erase any personal data we hold about you. This does
            not include any data we are obliged to keep for administrative,
            legal, or security purposes.
          </p>
        </section>
        <section className="pri-sec"  >
          <h2 className="pri-h2"  >Where your data is sent</h2>
          <p className="pri-p"  >
            Visitor comments may be checked through an automated spam detection
            service.
          </p>
        </section>
        <section className="pri-sec"  >
          <h2 className="pri-h2"  >Access data</h2>
          <p className="pri-p"  >
            {" "}
            The website operator or page provider collects data about accesses
            to the site and saves them as “server log files”. The following data
            is logged in this way:{" "}
          </p>
          <ul className="bullet-list">
            <li style={{color:"black"}} >Visited website</li>
            <li style={{color:"black"}} >Time at time of access</li>
            <li style={{color:"black"}} >Amount of data sent in bytes</li>
            <li style={{color:"black"}} >Source/reference from which you reached the site</li>
            <li style={{color:"black"}} >Used Browser</li>
            <li style={{color:"black"}} >Operating system in use</li>
            <li style={{color:"black"}} >Used IP address</li>
          </ul>
          <p className="pri-p"    >
            The data collected is only used for statistical analysis and to
            improve the website. However, the website operator reserves the
            right to check the server log files subsequently if concrete
            indications of illegal use are found.
          </p>
        </section>
        <section  className="pri-sec"  >
          <h2 className="pri-h2"  >Handling of personal data</h2>
          <p className="pri-p"   >
            The website operator collects, uses and passes on your personal data
            only if this is permitted by law or if you consent to the collection
            of data. Personal data is defined as all information which serves to
            determine your person and which can be traced back to you – such as
            your name, e-mail address and telephone number.{" "}
          </p>
        </section>
        <section  className="pri-sec"   >
          <h2 className="pri-h2"   >Handling of contact data</h2>
          <p  className="pri-p"   >
            If you contact the website operator through the contact options
            offered, your details will be stored for a period of six months so
            that they can be used to process and answer your enquiry and in the
            event of follow-up questions. This data will not be passed on to
            third parties without your consent.
          </p>
        </section>
      </main>
      <br />
      <br />
      <br />
      <br />
      {/* <footer className="footer" >
        <p style={{color:"black"}} >&copy; {currentYear} RedRuby Technologies. All rights reserved.</p>
        <a href= "/#home"> <p style={{color:"lightblue"}} >Home</p> </a>
      </footer> */}
    </div>
  );
};

export default PrivacyPolicyPage;
