






import React, { useEffect } from 'react';



import aws  from "../../assets/tech/aws.png"

import google from "../../assets/tech/googlecloud.png"
import devops from "../../assets/tech/devops.png"

import jenkins  from "../../assets/tech/jenkins.png"

import docker from "../../assets/tech/docker.png"
import kubernetes from "../../assets/tech/kubernetes.png"
import ansible  from "../../assets/tech/ansible.png"

import puppet from "../../assets/tech/puppet.png"
import grafana from "../../assets/tech/grafana.png"
import signoz  from "../../assets/tech/signoz.png"




import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Cloudtech = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
      <div className='container'>
        <div className='row'>
        <h2>Cloud and Devops
</h2>
          <div className='col-xs-12 col-md-10'  >
            {' '}
         <div style={{ margin:30, display: 'flex', flexWrap: 'wrap'  , justifyContent:'space-evenly' }}   >  
         
         

         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
         <img  style={{height:150 , width:150  }}  data-aos='fade-up' src={aws} className='grayscale-img' alt='' />{' '}
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>AWS </p>
</div> 
         
       
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
<img style={{height:150 , width:150 }}  data-aos='fade-up' src={google} className='img-responsive grayscale-img' alt='' />{' '}
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>GOOGLE </p>
</div> 

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
<img style={{height:150 , width:150 }}  data-aos='fade-up' src={devops} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>GITLAB </p>
       </div>  
            
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       <img  style={{height:150 , width:150  }}  data-aos='fade-up' src={jenkins} className='grayscale-img' alt='' />{' '}

       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>JENKINS </p>
       </div> 
          
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={docker} className='img-responsive grayscale-img' alt='' />{' '}

       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>DOCKER </p>
       </div> 

          
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={kubernetes} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>KUBERNETES </p>
       </div>     
       
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       <img  style={{height:150 , width:150  }}  data-aos='fade-up' src={ansible} className='grayscale-img' alt='' />{' '}

       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>ANSIBLE </p>
       </div> 

       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={puppet} className='img-responsive grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>PUPPET </p>
       </div> 

       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={grafana} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>GRAFANA </p>
       </div>    
            
          
            
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={signoz} className='img-responsive grayscale-img' alt='' />{' '}   

       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>UPTRACE</p>
       </div> 
            
        
            
             </div>
       
       
          </div>
          <div className='col-xs-12 col-md-'  data-aos='fade-up'>
            <div className='about-text'>

           
<p>A modern platform for easy scalability, cost-effective hosting and a range of increasingly advanced services. With our deep expertise in all Public Clouds, hybrid and multi cloud set-ups, we help our clients in cloud strategy, migration and continuous DevOps automation

</p>
         
             
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default  Cloudtech