






import about from "../../assets/icons/consultant.png"
import React, { useEffect } from 'react';
import { Card } from "@mui/material";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Conpara = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
    <Card>
    <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'  >
            {' '}
            <img style={{height:300  }}  data-aos='fade-right' src={about} className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'  data-aos='fade-left'>
            <div className='about-text'>
              <h2>Consultancy Services</h2>

             


              <p> Not sure where to begin? Our experienced consultants offer valuable guidance and strategic insights.
</p>
           <p>We'll help you assess your tech needs, identify the best solutions, and develop a roadmap for success.

       </p>    
            </div>
          </div>
        </div>
      </div>
        </Card>
    </div>
  )
}

export default  Conpara