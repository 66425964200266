import React, { useEffect } from 'react';
import ParticlesBg from 'particles-bg';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Techheader = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
  
    });
  }, []);

  return (
    <header >
      <div style={{ backgroundColor: '#2C5CDF' }} className='intro' data-aos='fade-up'>
        <ParticlesBg
          type='lines'
       
        
          bg={{ zIndex: 0, position: 'absolute', top: 0 }}
        />
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div
                className='col-md-8 col-md-offset-2 intro-text'
                data-aos='fade-up'
                data-aos-delay='300'
              >
                <h2 style={{color:"white"}} >Technologies we work
</h2>
                <div>
                  <h4 style={{ color: 'white' }}>
</h4>
                  <p>Product development done right, with breakthrough technologies and Agile processes. The technologies and expertise behind our impactful solutions.


</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
