import React, { useEffect, useRef, useState } from 'react';
import ParticlesBg from 'particles-bg';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

export const Header = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
  
    });
  }, []);

  const gradients = [

    'linear-gradient(to down, #B0E0E6, #2C5CDF)',

  ];

  const [randomGradient, setRandomGradient] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * gradients.length);
      setRandomGradient(gradients[randomIndex]);
    }, 500); 

    return () => clearInterval(intervalId); 
  }, [gradients]);

  return (
    <header id='header'>
      <div style={{ backgroundColor: "#2234ae",
              backgroundImage: "linear-gradient(315deg, #2234ae 0%, #191714 100%)" }} className='intro' data-aos='fade-up'>
        <ParticlesBg
          type='cobweb'
          color='#ffffff'
          num={200}
          bg={{ zIndex: 0, position: 'absolute', top: 0 }}
        />
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div
                className='col-md-8 col-md-offset-2 intro-text'
                data-aos='fade-up'
                data-aos-delay='300'
              >
                <h2 style={{color:"white"}}>{props.data ? props.data.title : 'Loading'}<span></span></h2>
                <div>
                  <h4 style={{ color: 'white' }}>{props.data ? props.data.paragraph : 'Loading'}</h4>
                  <p>{props.data ? props.data.subtitle : 'Loading'}</p>
                </div>
                {/* <a href='#about'  style={{ backgroundImage: randomGradient, fontWeight:"bold" }} className='btn btn-custom btn-lg page-scroll' data-aos='fade-up'>
                  {"Get Started"}
                </a>{' '} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
