





import React, { useEffect , useState } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
import EnquiryDialog from '../../dialog';
export const OdooContact = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
     
    });
  }, []);
  const [isDialogOpen, setIsDialogOpen] = useState(false);


  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleEnquirySubmit = (formData) => {
    console.log("Submitted:", formData);
    // Add your form submission logic here
  };

  return (
    <div id='about123'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-12'  >
            {' '}
           
          </div>  <h2  >Ready to take your organization to the next level?
</h2>
          
          


       
              <p  > <strong>Contact RedRuby Technologies today to discuss how our customized Unity ERP solutions can help you achieve your operational goals.
    </strong> <br/> 
    Contact us today to take your organisation to the next level!</p>
           
 
<div style={{marginBottom:20  , display:"flex"   , justifyContent:"center", alignItems:"center"}} className="px-5 py-4 pb-5">
                <a href="/careers" > <button  type="submit" style={{ fontWeight:"bold" }} data-mdb-button-init data-mdb-ripple-init  className='btn btn-custom btn-lg page-scroll' data-aos='fade-up'>Get In Touch With Us</button> </a>    
                  </div>
             
        </div>
      </div>
      <EnquiryDialog isOpen={isDialogOpen} onClose={handleDialogClose} onSubmit={handleEnquirySubmit} />
    </div>
  )
}
