import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import logo from "../assets/favicon.png";

export const Navigation = (props) => {
  const [activeSection, setActiveSection] = useState(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(gsap);

    const hoverAnimation = (ref) => {
      gsap.to(ref.current, {
        scale: 1.3,
        duration: 0.2,
        onComplete: () => {
          gsap.set(ref.current, { scale: 1 });
        },
      });
    };
    
    const unhoverAnimation = (ref) => {
      gsap.to(ref.current, {
        scale: 1,
        duration: 0.1,
      });
    };

    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      let prevSection = null;

      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight) {
          prevSection = section.id;
        }
      });

      setActiveSection(prevSection);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className=''>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            <span className='sr-only'>Toggle navigation</span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
          </button>
          <a className='navbar-brand page-scroll' href='/#home'>
            <div style={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
              <img width={50} src={logo} alt="logo" style={{ marginRight: 5 }} />
              <span style={{ marginTop: 10 }}> RedRuby Technologies</span>
            </div>
          </a>
        </div>
        
        <div className='collapse navbar-collapse' id='bs-example-navbar-collapse-1'>
          <ul className='nav navbar-nav navbar-right'>
  <li className={activeSection === 'home' ? 'active' : null} ref={ref2}>
              <a href='/#home' className='page-scroll'>
             Home
              </a>
            </li>

            <li className={activeSection === 'about' ? 'active' : null} ref={ref1}>
            <div class="dropdown">
              <a href='/#about' className='dropbtn page-scroll'>
                About Us
              </a>
              <div class="dropdown-content">
    <a href="/#features">Talent</a>
    <a href="/#values">Values</a>
    <a href="/technologies">What We Do</a>

  </div>
  </div>
            </li>




          
            <li className={activeSection === 'services' ? 'active' : null} ref={ref3}>
            
            
<div class="dropdown">
<a href='/#services' className='dropbtn page-scroll'>
               Products
              </a>

  <div class="dropdown-content">
    <a href="/gyanbuddy">GyanBuddy KMS</a>
    <a href="/unityerp">Unity ERP Solutions</a>
    <a href="/facetag">Face-Tag</a>
    <a href="/infrastructure">Infrastructure Solutions</a>
  </div>
</div>
             
              
            </li>
            <li className={activeSection === 's' ? 'active' : null} ref={ref4}>
              
            
<div class="dropdown">
              <a href='/#team' className='dropbtn page-scroll'>
              Services
              </a>
              
              
  <div class="dropdown-content">
    <a href="/itservices">Complete IT services</a>
    <a href="/kavalan">Cyber Kavalan</a>
    <a href="/consulting">Consulting services</a>
   
  </div>
              </div>
            </li>
            {/* <li className={activeSection === 'values' ? 'active' : null} ref={ref5}>
              <a href='/#values' className='page-scroll'>
                Values
              </a>
            </li> */}
            {/* <li className={activeSection === 'development' ? 'active' : null} ref={ref6}>
              <a href='/#development' className='page-scroll'>
                What We do ?
              </a>
            </li> */}
            <li className={activeSection === 'footers' ? 'active' : null} ref={ref7}>
              <a href='/#footers' className='page-scroll'>
                Contact Us
              </a>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  );
};
