






import about from "../assets/home/contactus.jpg"
import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const About = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'  >
            {' '}
            <img style={{height:600  }}  data-aos='fade-up' src={about} className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'  data-aos='fade-up'>
            <div className='about-text'>
              <h2>About Us</h2>
              <p> RedRuby Technologies is a pioneering startup dedicated to bridging the digital divide through innovative solutions. We are passionate about crafting ground-up technologies that empower businesses and organizations of all sizes.
</p>
           <p>Our expertise lies in product development, solution design, and delivering best-in-class IT infrastructure solutions. We have a proven track record of supporting a diverse clientele, ranging from budding startups and established SMEs to prestigious blue-chip companies. We cater to the specific needs of government establishments and educational institutions through our exclusive service offerings.
</p>
<p>RedRuby Technologies has garnered extensive experience in providing high-quality IT services across various industry verticals and technological landscapes. We foster a collaborative culture, allowing us to seamlessly integrate with your existing team and processes.</p>
              <h3>Why Us</h3>
              <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                   
                     
                          <li style={{fontWeight:500}}>Customer-Centric Approach</li>
                          <li style={{fontWeight:500}} >Operational Excellence</li>
                          <li  style={{fontWeight:500}} >Innovation at Our Core</li>
                         
                        
                   
                  </ul>
                </div>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                  <li  style={{fontWeight:500}} >Unmatched Expertise</li>
                          <li  style={{fontWeight:500}} >Scalable Solutions</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default  About