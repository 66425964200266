import { useState, useEffect } from 'react'

import JsonData from '../../../../data/data.json'


import { Navigation } from '../../../navigation'


import Footer from '../../../footer'
import { Attenheader } from './attendanceheader'
import { Attenabout } from './attenabout'
import { Attenben } from './attenben'
import { Attenpics } from './attenpic'
import { AttContact } from './contact'



const Attenfirstpage = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
    window.scrollTo(0, 0);
  }, [])

  return (
    <div >
   <Navigation></Navigation>
     <Attenheader></Attenheader>
     <Attenabout></Attenabout>
     <Attenben></Attenben>

     <Attenpics></Attenpics>
     <AttContact></AttContact>
    <Footer/>
    </div>
  )
}

export default Attenfirstpage
