import { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { Features } from './components/features'

import { Services } from './components/services'
import {Product} from './components/product'

import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import { OurValues } from './components/ourvalues'
import Development from './components/development'
import Footer from './components/footer'
import About from './components/about'
import ParticlesBg from 'particles-bg'
import { Contact } from './components/contact'


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const Firstpage = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (

    <div>

    <Navigation></Navigation>
       <Header data={landingPageData.Header} />
       <About data={landingPageData.About}  />
       <Features data={landingPageData.Features}  />
       <Product />
       <Services data={landingPageData.Services}  />
       <OurValues />
    <Contact></Contact>

       
       <Footer />
     </div>



  )
}

export default Firstpage
