






import about from "../../../assets/imageforabout/illustration.jpg"
import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
export const Compabout = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
     
    });
  }, []);
  return (
    <div id='about1'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'  >
            {' '}
            <img style={{height:300 , borderRadius:30  }}  data-aos='fade-up' src={about} className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'  data-aos='fade-up'>
            <div className='about-text'>
              <h2 >End to End Complete It Services
</h2>
              <p   > At RedRuby Technologies, we understand the critical role of a robust IT infrastructure in today's business landscape. Downtime is costly, and security breaches can be devastating. That's why we offer comprehensive IT services designed to keep your systems running smoothly, securely, and efficiently. </p>
           
            

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
