import { useState, useEffect } from 'react'

import JsonData from '../../../data/data.json'

import { Odooheader } from './odooheader'
import {  Odoovalues } from './odooabout'
import { Navigation } from '../../navigation'

import { Odoogallery } from './odoogallery'
import { Odoobenifits } from './odoocontent'
import Footer from '../../footer'
import { Odoobigpage } from './odoobigpage'
import { OdooContact } from './odoocontact'


const Odoofirstpage = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
    window.scrollTo(0, 0);
  }, [])

  return (
    <div >
   <Navigation></Navigation>
      <Odooheader/>
      <Odoobigpage/>
      <Odoovalues />
      <Odoobenifits/>
<Odoogallery/>
<OdooContact></OdooContact>
<Footer />

    </div>
  )
}

export default Odoofirstpage
