
import React, { useEffect } from 'react';
import mysql from "../../assets/tech/mysql.png"
import mariadb from "../../assets/tech/mariadb.png"
import postgresql from "../../assets/tech/postgre.png"
import elasticsearch from "../../assets/tech/elasticsearch.png"
import clickhouse from "../../assets/tech/clickhouse.png"
import redis from "../../assets/tech/redis.png"
import prometheus from "../../assets/tech/prometheus.png"
import amazon from "../../assets/tech/amazonrds.png"
import amazondynamodb from "../../assets/tech/amazondynamodb.png"
import mongodb from "../../assets/tech/mongodb.png"


import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Datatech = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
      <div className='container'>
        <div className='row'>
        <h2>DataBase
</h2>
          <div className='col-xs-12 col-md-10'  >
            {' '}
         <div style={{ margin:30, display: 'flex', flexWrap: 'wrap'  , justifyContent:'space-evenly' }}   >  
         
         
         <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'}}>
       
         <img  style={{height:150 , width:150  }}  data-aos='fade-up' src={mysql} className='grayscale-img' alt='' />{' '}
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>MYSQL </p>
</div> 
         
       
<div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'}}>
       
<img style={{height:150 , width:150 }}  data-aos='fade-up' src={mariadb} className='img-responsive grayscale-img' alt='' />{' '}
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>MARIADB </p>
</div> 

<div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'}}>
       
<img style={{height:150 , width:150 }}  data-aos='fade-up' src={postgresql} className='img-responsive grayscale-img' alt='' />{' '} 
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>POSTGRESQL </p>
</div> 
<div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'}}>
       
<img  style={{height:150 , width:150  }}  data-aos='fade-up' src={elasticsearch} className='grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>ELASTICSEARCH </p>
       </div> 
            
       <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'}}>
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={clickhouse} className='img-responsive grayscale-img' alt='' />{' '}

       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>CLICKHOUSE </p>
       </div> 
          
       <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'}}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={redis} className='img-responsive grayscale-img' alt='' />{' '} 
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>REDIS </p>
</div>   
<div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'}}>
       
<img  style={{height:150 , width:150  }}  data-aos='fade-up' src={prometheus} className='grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>PROMOTHEUS </p>
       </div>  
        
       <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'}}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={amazon} className='img-responsive grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>AMAZON </p>
       </div>  
        
       <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'}}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={amazondynamodb} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>AMAZON DYNAMODB </p>
       </div>     
          
       <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'}}>
       
       <img  style={{height:150 , width:150  }}  data-aos='fade-up' src={mongodb} className='grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>MONGODB </p>
       </div> 

          
         
            

             </div>
       
       
          </div>
          <div className='col-xs-12 col-md-'  data-aos='fade-up'>
            <div className='about-text'>

           
<p>Thanks to modern technologies, we can work with data on a large scale, automating the process and turning Big Data sets into ready and understandable conclusions, trends or recommendations.Thanks to modern technologies, we can work with data on a large scale, automating the process and turning Big Data sets into ready and understandable conclusions, trends or recommendations.



</p>
         
             
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default  Datatech