






import React, { useEffect } from 'react';


import flutter from "../../assets/tech/flutter.png"
import reactnative from "../../assets/tech/reactnative.png"
import swift from "../../assets/tech/swift.png"
import PWA from "../../assets/tech/PWA.png"
import firebase from "../../assets/tech/firebase.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Mobiletech = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
      <div className='container'>
        <div className='row'>
        <h2> Mobile Apps / PWA
</h2>
          <div className='col-xs-12 col-md-12'  >
            {' '}
         <div style={{ marginBottom:30, display: 'flex', flexWrap: 'wrap'  , justifyContent:'space-evenly' }}   >  
         

         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
         <img  style={{height:150 , width:150  }}  data-aos='fade-up' src={flutter} className='grayscale-img' alt='' />{' '}
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>FLUTTER </p>
</div> 
         
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
<img style={{height:150 , width:150 }}  data-aos='fade-up' src={reactnative} className='img-responsive grayscale-img' alt='' />{' '}
            
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>REACTNATIVE </p>
       </div> 
          
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={swift} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>SWIFT </p>
       </div> 
          
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={PWA} className='img-responsive grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>PWA </p>
       </div> 
           
            
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={firebase} className='grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>FIREBASE </p>
       </div> 

        
            
             </div>
       
       
          </div>
          <div className='col-xs-12 col-md-'  data-aos='fade-up'>
            <div className='about-text'>

           
<p>When it comes to mobile platforms, we use a number of dedicated technologies to meet the modern demands of mobile users - accessible, fast, easy to navigate and native in experience

</p>
         
             
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default  Mobiletech