
import React, { useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import gsap from "gsap";
export const PulseAIOps = () => {

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  useEffect(() => {
    const hoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1.3,
        duration: 0.2,
        onComplete: () => {
          gsap.set(ref, { scale: 1 });
        },
      });
    };

    const unhoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1,
        duration: 0.1,
      });
    };

    const addHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.addEventListener("mouseenter", () => hoverAnimation(element));
        element.addEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    const removeHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.removeEventListener("mouseenter", () => hoverAnimation(element));
        element.removeEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    addHoverListeners(ref1);
    addHoverListeners(ref2);
    addHoverListeners(ref3);
    addHoverListeners(ref4);
    addHoverListeners(ref5);
    return () => {
      removeHoverListeners(ref1);
      removeHoverListeners(ref2);
      removeHoverListeners(ref3);
      removeHoverListeners(ref4);
      removeHoverListeners(ref5);
    };
  }, []);

  return (
    <div id='services' className='text-center' style={{overflow:"hidden"}}>
      <div className='container'>
        <div className='section-title' data-aos='fade-up'>
        <h2>Pulse Assest AIOPS

</h2>
        <h3>Comprehensive Asset Management Solution

</h3>
          <p>
          Pulse AssetOps, powered by opensource platform, empowers you to effectively manage both IT and non-IT assets within your network.

          </p>
        </div>
        <div className='row' data-aos='fade-up'

        >
          <div ref={ref1} className='col-md-4'>
         


          <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>



              <div className='service-desc' data-aos='fade-up'>
                <h3>Centralized Asset Inventory</h3>
                <p>Gain a clear view of all your assets, including hardware, software, licenses, and facilities, in one centralized location.</p>
              </div>
       
          </div>

          <div  ref={ref2} className='col-md-4'>
           
          <i class="fa fa-crosshairs" aria-hidden="true"></i>

          {/* <i class="fa fa-server" aria-hidden="true"></i> */}
              
              <div className='service-desc' data-aos='fade-up'>
                <h3>Detailed Asset Tracking</h3>
                <p>Track asset lifecycles, maintenance history, and warranty information for informed decision-making.
</p>
              </div>
          
          </div>

          <div  ref={ref3} className='col-md-4'>
           
              <i className="fa fa-sitemap"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Automated Workflows</h3>
                <p>Streamline your workflows with automated tasks and notifications for asset tracking, maintenance scheduling, and expiration alerts.
</p>
              </div>
        
          </div>
          <div  ref={ref4} className='col-md-12'>
           
          <i class="fa fa-ticket" aria-hidden="true"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Enhanced Reporting</h3>
                <p>Generate insightful reports to track asset utilization, optimize resource allocation, and identify potential risks.</p>
              </div>
      
          </div>

        </div>
      </div>
    </div>
  );
}

