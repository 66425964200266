






import about from "../../../assets/imageforabout/illustration.jpg"
import React, { useEffect , useState } from 'react';
import EnquiryDialog from "../../dialog";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
export const Contactcons = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
     
    });
  }, []);



  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleEnquirySubmit = (formData) => {
    console.log("Submitted:", formData);
    // Add your form submission logic here
  };
  return (
    <div id='about123'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-12'  >
            {' '}
           
          </div>  <h2   >Let Us Help You Navigate Your Project Landscape

</h2>
          
          



          
              <p  > <strong>Whether you're brimming with innovative ideas or facing complex technological challenges, RedRuby Technologies is here to guide you. </strong>  <br/>  <br/> Contact us today to schedule a consultation and explore how our expertise can help you achieve your project goals.

</p>
           
 
<div style={{marginBottom:20  , display:"flex"   , justifyContent:"center", alignItems:"center"}} className="px-5 py-4 pb-5">
                <a href="/careers" > <button  type="submit" style={{ fontWeight:"bold" }} data-mdb-button-init data-mdb-ripple-init  className='btn btn-custom btn-lg page-scroll' data-aos='fade-up'>Get In Touch With Us</button> </a>    
                  </div>
             
        </div>
      </div>
      <EnquiryDialog isOpen={isDialogOpen} onClose={handleDialogClose} onSubmit={handleEnquirySubmit} />
    </div>
  )
}
