






import about from "../../../assets/imageforabout/discussing.jpg"
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
export const Consabout = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
     
    });
  }, []);
  return (
    <div id='about1'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'  >
            {' '}
            <img style={{height:350   }}  data-aos='fade-up' src={about} className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'  data-aos='fade-up'>
            <div className='about-text'>
              <h2>strategic and business consultation
</h2>
              <p>At RedRuby Technologies, we understand that every project is unique. Whether you're embarking on a complex web application development journey, navigating the complexities of digital migration, or seeking expert advice on any technology-related challenge, RedRuby is your one-stop shop for comprehensive consulting services.

 </p>
           
            

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
