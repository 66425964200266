import { Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

export const Odoobenifits = () => {

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  useEffect(() => {
    const hoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1.3,
        duration: 0.2,
        onComplete: () => {
          gsap.set(ref, { scale: 1 });
        },
      });
    };

    const unhoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1,
        duration: 0.1,
      });
    };

    const addHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.addEventListener("mouseenter", () => hoverAnimation(element));
        element.addEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    const removeHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.removeEventListener("mouseenter", () => hoverAnimation(element));
        element.removeEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    addHoverListeners(ref1);
    addHoverListeners(ref2);
    addHoverListeners(ref3);
    addHoverListeners(ref4);
    addHoverListeners(ref5);
    return () => {
      removeHoverListeners(ref1);
      removeHoverListeners(ref2);
      removeHoverListeners(ref3);
      removeHoverListeners(ref4);
      removeHoverListeners(ref5);
    };
  }, []);

  return (
    <div id='services' className='text-center'  style={{overflow:"hidden"}} >
      <div className='container'>
        <div className='section-title' data-aos='fade-up' >
          <h2>How Unity ERP Benefits Your Organization</h2>
        </div>
        <div className='row' data-aos='fade-up' > 
          <div ref={ref1} className='col-md-4'>
         
              <i className="fa fa-signal"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Improved Efficiency</h3>
                <p>Automate key business processes to streamline workflows and boost productivity across departments.</p>
              </div>
       
          </div>

          <div ref={ref2} className='col-md-4'>
         
              <i className="fa fa-bars"></i>
              
              <div className='service-desc' data-aos='fade-up'>
                <h3>Enhanced Data Management</h3>
                <p>Streamline Your Operations Across Sectors</p>
              </div>
        
          </div>

          <div ref={ref3} className='col-md-4'>
           
              <i className="fa fa-sitemap"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Increased Collaboration</h3>
                <p>Foster seamless collaboration between teams with real-time data access and communication tools</p>
              </div>
         
          </div>
          <div ref={ref4} className='col-md-6'>
       
              <i className="fa fa-thumbs-up"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Reduced Costs</h3>
                <p>Eliminate the need for multiple, siloed software solutions. Unity offers an all-in-one platform, reducing overall IT costs</p>
              </div>
   
          </div>
          <div  ref={ref5} className='col-md-6'>
           
              <i className="fa fa-truck"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Scalability</h3>
                <p>The platform scales alongside your organization's needs, easily accommodating growth</p>
              </div>
      
          </div>
        </div>
      </div>
    </div>
  );
}

