






import React, { useEffect } from 'react';
import django from "../../assets/tech/django.png"

import fastapi from "../../assets/tech/fastapi.png"
import flask from "../../assets/tech/flask.png"

import codeigniter from "../../assets/tech/codeigniter.png"


import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Frametech = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
      <div className='container'>
        <div className='row'>
        <h2>Frameworks
</h2>
          <div className='col-xs-12 col-md-12'  >
            {' '}
         <div style={{ marginBottom:30, display: 'flex', flexWrap: 'wrap'  , justifyContent:'space-evenly' }}   >  
         
         <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
         <img  style={{height:150 , width:200  }}  data-aos='fade-up' src={django} className='grayscale-img' alt='' />{' '}

       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>DJANGO </p>
       </div>    
         
          
       <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       <img style={{height:150 , width:200 }}  data-aos='fade-up' src={fastapi} className='img-responsive grayscale-img' alt='' />{' '}

       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>FASTAPI </p>
       </div> 
         
       <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       <img style={{height:150 , width:200 }}  data-aos='fade-up' src={flask} className='img-responsive grayscale-img' alt='' />{' '}   

       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>FLASK </p>
       </div> 



       <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={codeigniter} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>CODEIGNITER </p>
       </div> 
         

            
             </div>
       
       
          </div>
          <div className='col-xs-12 col-md-'  data-aos='fade-up'>
            <div className='about-text'>

           
<p>We have deep expertise in leveraging wide array of frameworks for faster development and easier maintenance

</p>
         
             
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default  Frametech