






import about from "../../assets/icons/web-development.png"
import React, { useEffect } from 'react';
import { Card } from "@mui/material";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Webpara = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
    <Card>
    <div className='container'>
        <div className='row'>
        
          <div className='col-xs-12 col-md-6'  data-aos='fade-left'>
            <div className='about-text'>
              <h2>Web Application Development</h2>

             
           

              <p>    Need a dynamic and engaging web application? We excel in developing custom web applications that cater to your specific needs.
</p>
           <p>  From simple web portals to complex enterprise solutions, we deliver exceptional results.
</p>

            
            
            </div>
          </div>
          <div className='col-xs-12 col-md-6'  >
            {' '}
            <img style={{height:300  }}  data-aos='fade-right' src={about} className='img-responsive' alt='' />{' '}
          </div>
        </div>
      </div>
        </Card>
    </div>
  )
}

export default  Webpara