import { Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

export const OurServices = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);



  useEffect(() => {
    const hoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1.3,
        duration: 0.2,
        onComplete: () => {
          gsap.set(ref, { scale: 1 });
        },
      });
    };

    const unhoverAnimation = (ref) => {
      gsap.to(ref, {
        scale: 1,
        duration: 0.1,
      });
    };

    const addHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.addEventListener("mouseenter", () => hoverAnimation(element));
        element.addEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    const removeHoverListeners = (ref) => {
      const element = ref.current;
      if (element) {
        element.removeEventListener("mouseenter", () => hoverAnimation(element));
        element.removeEventListener("mouseleave", () => unhoverAnimation(element));
      }
    };

    addHoverListeners(ref1);
    addHoverListeners(ref2);
    addHoverListeners(ref3);
    addHoverListeners(ref4);
    addHoverListeners(ref5);
    addHoverListeners(ref6);
    addHoverListeners(ref7);
    addHoverListeners(ref8);
    addHoverListeners(ref9);


    return () => {
      removeHoverListeners(ref1);
      removeHoverListeners(ref2);
      removeHoverListeners(ref3);
      removeHoverListeners(ref4);
      removeHoverListeners(ref5);
      removeHoverListeners(ref6);
      removeHoverListeners(ref7);
      removeHoverListeners(ref8);
      removeHoverListeners(ref9);

    };
  }, []);


  return (
    <div id='services' className='text-center' style={{overflow:"hidden"}}>
      <div className='container'>
        <div className='section-title' data-aos='fade-up'>
          <h2>Our Offers</h2>

       
        </div>
        <div className='row' data-aos='fade-up'

        >
          <div ref={ref1} className='col-md-3'>
         


          <i class="fa fa-desktop" aria-hidden="true"></i>



              <div className='service-desc' data-aos='fade-up'>
                <h3>Desktop Support</h3>
                <p>Resolve day-to-day user issues and ensure optimal desktop performance.
</p>
              </div>
       
          </div>

          <div ref={ref2} className='col-md-3'>
           
          <i class="fa fa-globe" aria-hidden="true"></i>

        
              
              <div className='service-desc' data-aos='fade-up'>
                <h3>Server Support</h3>
                <p>Maintain and manage your critical servers for maximum uptime and reliability.

</p>
              </div>
          
          </div>

          <div ref={ref3} className='col-md-3'>
           
              <i className="fa fa-signal"></i>
              <div className='service-desc' data-aos='fade-up'>
                <h3>Network Support</h3>
                <p>Optimize your network infrastructure for smooth data flow and secure connectivity.
</p>
              </div>
        
          </div>
        
          <div ref={ref4} className='col-md-3'>
           
           <i class="fa fa-cloud" aria-hidden="true"></i>
               <div className='service-desc' data-aos='fade-up'>
                 <h3>Cloud Solutions</h3>
                 <p>Leverage the benefits of cloud computing for scalability, flexibility, and cost savings.</p>
               </div>
       
           </div>
           <div ref={ref5} className='col-md-3'>
           
           <i class="fa fa-user-secret" aria-hidden="true"></i>
               <div className='service-desc' data-aos='fade-up'>
                 <h3>Cybersecurity Services</h3>
                 <p>Protect your business from cyber threats with advanced security solutions and ongoing monitoring.</p>
               </div>
       
           </div>

           <div ref={ref6} className='col-md-3'>
           
           <i class="fa fa-files-o" aria-hidden="true"></i>
               <div className='service-desc' data-aos='fade-up'>
                 <h3>IT Infrastructure Management</h3>
                 <p>Gain centralized control and oversight of your entire IT infrastructure.</p>
               </div>
       
           </div>
          
          
       
        <div ref={ref7} className='col-md-3'>
           
           <i class="fa fa-recycle" aria-hidden="true"></i>
               <div className='service-desc' data-aos='fade-up'>
                 <h3>Data Backup and Recovery</h3>
                 <p>Protect your valuable data from disasters and ensure quick recovery in case of incidents.</p>
               </div>
       
           </div>
          
        <div ref={ref8} className='col-md-3'>
           
           <i class="fa fa-crosshairs" aria-hidden="true"></i>
               <div className='service-desc' data-aos='fade-up'>
                 <h3>IT Procurement</h3>
                 <p>Benefit from our expertise in selecting and acquiring the right hardware and software solutions.</p>
               </div>
               </div>
           </div>
           <div ref={ref9} className='col-md-12'>
           
           <i class="fa fa-shield" aria-hidden="true"></i>
               <div className='service-desc' data-aos='fade-up'>
                 <h3>IT Project Management</h3>
                 <p>Plan, execute, and monitor IT projects efficiently to achieve desired outcomes.</p>
               </div>
       
           </div>
      </div>
    </div>
  );
}

