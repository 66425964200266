






import React, { useEffect } from 'react';


import grafana from "../../assets/tech/grafana.png"

import DJ3 from "../../assets/tech/d3js.png"
import tabelu from "../../assets/tech/tabelu.png"
import Apache from "../../assets/tech/Apachespark.png"

import hadoop from "../../assets/tech/hadoop.png"
import python from "../../assets/tech/python.jpeg"


import AOS from 'aos';
import 'aos/dist/aos.css'; 
 const Ana = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
      <div className='container'>
        <div className='row'>
        <h2>Data analytics
</h2>
          <div className='col-xs-12 col-md-12'  >
            {' '}
         <div style={{ marginBottom:30, display: 'flex', flexWrap: 'wrap'  , justifyContent:'space-evenly' }}   >  
         
         
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
         <img  style={{height:150 , width:150  }}  data-aos='fade-up' src={python} className='grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>PYTHON </p>
       </div> 

       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={grafana} className='img-responsive grayscale-img' alt='' />{' '}
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>GRAFANA </p>
       </div> 
          
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={DJ3} className='img-responsive grayscale-img' alt='' />{' '} 
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>D3.JS </p>
</div> 
            
          
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
<img style={{height:150 , width:150 }}  data-aos='fade-up' src={tabelu} className='img-responsive grayscale-img' alt='' />{' '}
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>TABELU </p>
</div> 

         
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
<img style={{height:150 , width:150 }}  data-aos='fade-up' src={Apache} className='img-responsive grayscale-img' alt='' />{' '} 
            
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>APACHE </p>
       </div> 
            
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
       
       <img style={{height:150 , width:150 }}  data-aos='fade-up' src={hadoop} className='img-responsive grayscale-img' alt='' />{' '} 
       <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>HADOOP </p>
       </div> 


         
             </div>
       
       
          </div>
          <div className='col-xs-12 col-md-'  data-aos='fade-up'>
            <div className='about-text'>

           
<p>We offer end-to-end Data & Analytics services to help our customers leverage the power of Data and unlock powerful & actionable business insights




</p>
         
             
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default  Ana