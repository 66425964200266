






import React, { useEffect } from 'react';


import angular from "../../assets/tech/angular.png"
import react from "../../assets/tech/react.png"
import css from "../../assets/tech/css.png"
import html from "../../assets/tech/html.png"
import bootstrap from "../../assets/tech/bootstrap.jpeg"
import Dj3 from "../../assets/tech/d3js.png"
import native from "../../assets/tech/reactnative.png"
import webpack from "../../assets/tech/webpack.png"
import redux from "../../assets/tech/redux.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; 

import { Tooltip } from '@mui/material';



 const Frontend = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
     
    });
  }, []);
  return (
    <div id='about12345'>
      <div className='container'>
        <div className='row'>
        <h2>Frontend Engineering
</h2>
          <div className='col-xs-12 col-md-10'  >
            {' '}
         <div style={{ margin:30, display: 'flex', flexWrap: 'wrap'  , justifyContent:'space-evenly' }}   >  
         
         
         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
          <img  style={{height:150 , width:150  }}  data-aos='fade-up' src={html} className='grayscale-img' alt='' />
          <p  style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>HTML </p>
           </div> 

           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
            <img style={{height:150 , width:150 }}  data-aos='fade-up' src={css} className='img-responsive grayscale-img' alt='' />{' '}
            <p  style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>CSS </p>
           </div> 


           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
            <img style={{height:150 , width:150 }}  data-aos='fade-up' src={react} className='img-responsive grayscale-img' alt='' />{' '} 
            <p  style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>REACT </p>
           </div> 
            

           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
            <img style={{height:150 , width:150 }}  data-aos='fade-up' src={angular} className='img-responsive grayscale-img' alt='' />{' '} 
            <p  style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>ANGULAR </p>
           </div>   


           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
           <img style={{height:150 , width:150 }}  data-aos='fade-up' src={Dj3} className='img-responsive grayscale-img' alt='' />{' '} 

           <p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>D3.JS</p>
           </div>     

           

           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       
           <img style={{height:150 , width:150 }}  data-aos='fade-up' src={native} className='img-responsive grayscale-img' alt='' />{' '} 
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>NATIVE </p>
</div> 

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       
<img style={{height:150 , width:150 }}  data-aos='fade-up' src={bootstrap} className='img-responsive grayscale-img' alt='' />{' '} 
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>BOOTSTRAP </p>
</div> 


<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
       

<img style={{height:150 , width:150 }}  data-aos='fade-up' src={webpack} className='img-responsive grayscale-img' alt='' />{' '} 
<p style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>WEBPACK </p>
</div> 

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px'  }}>
<img style={{height:150 , width:150 }}  data-aos='fade-up' src={redux} className='img-responsive grayscale-img' alt='' />{' '} 

<p  style={{
    textAlign: 'center',
    padding: '5px 10px',
    fontSize: '0.8em',
    backgroundColor: '#e0e0e0', 
    borderRadius: '12px',
    fontWeight: 'bold', 
    marginTop: '10px'
  }}>REDUX </p>
</div> 


            
             </div>
       
       
          </div>
          <div className='col-xs-12 col-md-'  data-aos='fade-up'>
            <div className='about-text'>
            
           
<p>JavaScript in combination with HTML5 has revolutionized the way web and mobile applications are built and we are extremely adept at implementing wide array of JS frameworks

</p>
         
             
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default  Frontend